import React, { useState, useContext, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  Divider,
  Collapse,
  SvgIcon,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation, useHistory, Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import { AuthContext } from "../containers/AuthProvider";
import { sidebardWidth } from "../common/constants";
import "./listPadding.css";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const SideBar = React.memo((props) => {
  const classes = useStyle();
  const { sidebarList } = props;
  const theme = useTheme();
  const handleDrawerClose = () => {};
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState();
  const [open2, setOpen2] = useState();
  const { perms, admin } = useContext(AuthContext);
  function CheckPerm(permRoute) {
    if (!permRoute) return true;
    if (Array.isArray(permRoute)) {
      for (let e in permRoute) {
        if (perms?.map((c) => c.name).includes(permRoute[e])) {
          return true;
        }
      }
      return false;
    } else {
      return perms?.map((e) => e.name).includes(permRoute);
    }
  }
  const handleOpen = (e) => {
    if (open == e?.label) {
      setOpen("");
    } else {
      props.setOpen(true);
      setOpen(e?.label);
    }
  };
  const handleOpen2 = (e) => {
    if (open2 == e?.label) {
      setOpen2("");
    } else {
      props.setOpen(true);
      setOpen2(e?.label);
    }
  };

  useEffect(() => {
    if (!props.open) {
      setOpen("");
    }
  }, [props.open]);
  useEffect(() => {
    if (!props.open2) {
      setOpen2("");
    }
  }, [props.open2]);

  const active = (path) => {
    if (path) {
      const pathname = location.pathname.split("/");
      const p = path.split("/");
      if (pathname[pathname.length - 1] == p[p.length - 1]) {
        return true;
      }
    }
    return false;
  };

  const activeSubconmittee = (path) => {
    if (path) {
      const pathname = location.pathname.split("/");
      const p = path.split("/");
      for (let i = 1; i < p.length; i++) {
        if (p[i] != pathname[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <div className={classes.toolbar} style={{ marginTop: 15 }}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction == "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />

      {sidebarList.map((c, index) =>
        CheckPerm(c.perm) ? (
          <div key={index}>
            <List disablePadding={true}>
              {c?.path ? (
                <Link to={c?.path}>
                  <ListItem
                    disablePadding={true}
                    button={true}
                    className={
                      c.path
                        ? active(c.path)
                          ? classes.active
                          : classes.unactive
                        : classes.category
                    }
                    onClick={() => {
                      history.push(c.path);
                      handleOpen(c);
                    }}
                  >
                    {c?.icon && (
                      <ListItemIcon className={classes.categoryIcon}>
                        {admin ? (
                          <SvgIcon
                            component={c?.icon}
                            style={{
                              fill: active(c.path) ? "#036FE3" : "#414141",
                              // marginTop: 8,
                              width: 16,
                            }}
                          />
                        ) : (
                          c?.icon
                        )}
                      </ListItemIcon>
                    )}
                    <ListItemText primary={props.open ? c.label : ""} />
                  </ListItem>
                </Link>
              ) : (
                <ListItem
                  disablePadding={true}
                  button={true}
                  className={
                    open == c?.label ? classes.categoryActive : classes.category
                  }
                  onClick={() => {
                    if (c.onClick) {
                      c.onClick();
                    } else {
                      handleOpen(c);
                      for (let i of c?.children) {
                        if (i?.children) {
                          for (let t of i?.children) {
                            if (CheckPerm(t.perm)) {
                              handleOpen2(i);
                              return 1;
                            }
                          }
                        }
                      }
                    }
                  }}
                >
                  {c?.icon && (
                    <ListItemIcon className={classes.categoryIcon}>
                      {admin ? (
                        <SvgIcon
                          component={c?.icon}
                          style={{
                            fill: open == c?.label ? "#fff" : "#414141",
                            // marginTop: 8,
                            width: 16,
                          }}
                        />
                      ) : (
                        c?.icon
                      )}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={props.open ? c.label : ""} />

                  {open == c?.label ? (
                    <KeyboardArrowDown
                      style={{
                        fill: "#fff",
                        width: 18,
                      }}
                    />
                  ) : (
                    <ChevronRightIcon style={{ fill: "#414141", width: 18 }} />
                  )}
                </ListItem>
              )}

              <Collapse in={open == c.label}>
                {c?.children?.map((e, index1) =>
                  CheckPerm(e.perm) && !e?.hide ? (
                    <List key={index1} disablePadding={true}>
                      {e?.path ? (
                        <Link to={e?.path}>
                          <ListItem
                            disablePadding={true}
                            button
                            key={index1}
                            title={e.label}
                            // onClick={() =>
                            //   e.path ? history.push(e.path) : handleOpen2(e)
                            // }
                            className={`${classes.item} ${
                              activeSubconmittee(e?.path)
                                ? classes.active
                                : classes.unactive
                            }`}
                          >
                            {/* <ListItemIcon>{e.icon}</ListItemIcon> */}
                            {/* <SvgIcon>
                              <Circle />
                            </SvgIcon> */}
                            <ListItemText
                              primary={props.open ? e.label : ""}
                              style={{ paddingLeft: 5 }}
                            />
                          </ListItem>
                        </Link>
                      ) : (
                        <ListItem
                          disablePadding={true}
                          button
                          key={index1}
                          title={e.label}
                          onClick={() => handleOpen2(e)}
                          className={`${classes.item} ${
                            active(e?.path) ? classes.active : classes.unactive
                          }`}
                        >
                          {/* <ListItemIcon>{e.icon}</ListItemIcon> */}
                          {/* <SvgIcon>
                            <Circle />
                          </SvgIcon> */}
                          <ListItemText
                            primary={props.open ? e.label : ""}
                            style={{ paddingLeft: 5 }}
                          />
                        </ListItem>
                      )}
                      <Collapse
                        in={open2 == e.label}
                        timeout="auto"
                        unmountOnExit
                      >
                        {e?.children?.map((d, index2) =>
                          CheckPerm(d.perm) ? (
                            <Link to={d?.path} key={index2}>
                              <ListItem
                                button
                                title={d.label}
                                className={`${classes.itemLv2} ${
                                  activeSubconmittee(d.path)
                                    ? classes.active
                                    : classes.unactive
                                }`}
                                onClick={() =>
                                  d.path ? history.push(d.path) : handleOpen2(d)
                                }
                              >
                                {/* <ListItemIcon style={{ minWidth: 30 }}>
                                {d.icon}
                              </ListItemIcon> */}
                                {/* <SvgIcon>
                                  <Dot />
                                </SvgIcon> */}
                                <ListItemText
                                  primary={d.label}
                                  style={{ paddingLeft: 5 }}
                                />
                              </ListItem>
                            </Link>
                          ) : (
                            ""
                          )
                        )}
                      </Collapse>
                    </List>
                  ) : (
                    ""
                  )
                )}
              </Collapse>
            </List>
            {/* <Divider /> */}
          </div>
        ) : (
          ""
        )
      )}
    </Drawer>
  );
});

const useStyle = makeStyles((theme) => ({
  drawer: {
    width: sidebardWidth,
    flexShrink: 0,
    fontSize: "1rem",
    "& svg": {
      color: theme.palette.custom.primary,
    },
    "& a": {
      color: "#000",
      textDecoration: "none",
    },
  },
  drawerOpen: {
    width: sidebardWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 50,
    [theme.breakpoints.up("sm")]: {
      width: 50,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  active: {
    minHeight: 48,
    color: "#036FE3",
  },
  unactive: {
    minHeight: 48,
    background: "#fff",
    color: "#42526E",
  },
  category: {
    minHeight: 50,
    color: "#42526E",
  },
  categoryActive: {
    background: "#036FE3",
    borderTopRightRadius: "32px",
    borderBottomRightRadius: "32px",
    color: "#fff",
    "&:hover": {
      background: "#004da1",
    },
  },
  categoryIcon: {
    color: "#000",
    minWidth: 30,
  },
  item: {
    paddingLeft: 30,
    "& svg": {
      color: "#e0481ade",
    },
  },
  itemLv2: {
    paddingLeft: 50,
    "& svg": {
      color: "#07b41591",
    },
  },
  itemLv3: {
    paddingLeft: 50,
    "& svg": {
      color: "#07b41591",
    },
  },
}));
export default SideBar;
