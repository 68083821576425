import React, { useEffect, useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAPI, useFetch } from "../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";

export default React.memo((props) => {
  const {
    endpoint,
    queryField,
    labelField,
    valueField,
    value,
    handleChange,
    label,
    params,
    ...otherProps
  } = props;
  const [query, setQuery] = useState();
  const [ownVal, setOwnVal] = useState(null);
  useEffect(() => {
    if (value == undefined) {
      setOwnVal(null);
    }
    if (typeof value == "object") {
      setOwnVal(value);
    }
  }, [value]);
  // const { data, loading } = useFetch(
  //   endpoint && ["get", endpoint, JSON.stringify({ ...query, ...params })]
  // );
  const api = useAPI();
  const [click, setClick] = useState(false);
  const [timeout, setTime] = useState(null);
  const [res, setRes] = useState();
  useEffect(() => {
    clearTimeout(timeout);
    setTime(
      setTimeout(() => {
        setRes(
          endpoint && click == true
            ? api.fetcher(
                "get",
                endpoint,
                JSON.stringify({ ...query, ...params })
              )
            : null
        );
      }, 200)
    );
  }, [query, endpoint, params, click]);
  const display = otherProps?.display;
  useEffect(() => {
    setOwnVal(null);
  }, [display]);
  useEffect(() => {
    const _val = ownVal ? { ...ownVal } : null;
    setOwnVal(_val);
  }, []);
  const options = api?.data?.data || [];

  const location = useLocation();
  const [firstCheck, setFirstCheck] = useState(false);

  const { data: FileType } = useFetch([
    "get",
    "/unauth/fileType?is_pay_equal=2&is_devotee=2",
  ]);

  const fileTypeData = useMemo(() => {
    let result = [];
    if (FileType?.data) {
      result = FileType?.data;
    }
    return result;
  }, [FileType]);

  useEffect(() => {
    if (location?.search && firstCheck == false && fileTypeData.length > 0) {
      let searchParams = location.search.split("&");
      searchParams.forEach((element) => {
        let data_params = element.split("=");
        if (data_params[0] == "file_type_id") {
          fileTypeData.forEach((valueOption) => {
            if (valueOption?.id == data_params[1] && label == "Loại hồ sơ") {
              handleChange(valueOption?.[valueField]);
              setOwnVal(valueOption);
            }
          });
        }
      });
      setFirstCheck(true);
      setFirstCheck(true);
    }
  }, [location, firstCheck, handleChange, fileTypeData, valueField]);
  return (
    <div onClick={() => setClick(true)}>
      <Autocomplete
        size={"small"}
        includeInputInList={true}
        openOnFocus={true}
        loading={api?.loading}
        loadingText={"Đang tải"}
        noOptionsText={"Không có dữ liệu"}
        value={ownVal}
        options={options}
        getOptionSelected={(o, v) => o?.id == v?.id}
        getOptionLabel={(option) => option?.[labelField]}
        renderOption={(option, state) => {
          return <div style={{ padding: 5 }}>{option?.[labelField]}</div>;
        }}
        onChange={(event, value, reason) => {
          if (!value) {
            handleChange(value);
            setOwnVal(value);
          } else {
            handleChange(value?.[valueField]);
            setOwnVal(value);
          }
        }}
        onClose={() => {
          setQuery();
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={otherProps?.variant}
            label={label}
            onChange={(event) => setQuery({ [queryField]: event.target.value })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {api?.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
});
