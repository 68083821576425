import React from "react";
import { FormControl, TextField, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import Moment from "moment";

export default React.memo((props) => {
  const { value, label, handleChange, ...otherProps } = props;
  const classes = useStyle();
  return (
    <div>
      <Typography
        style={{
          fontSize: "1rem",
          lineHeight: 1.43,
          fontWeight: 800,
          paddingBottom: '10px'
        }}
      >
        {label}
      </Typography>

      <div className={classes.root}>
        <div style={{ width: "50%" }}>
          <FormControl
            style={{
              width: "100%",
              paddingRight: "10px",
            }}
            {...otherProps.formControlProps}
          >
            <TextField
              variant="outlined"
              label="Từ"
              size="small"
              fullWidth
              value={value?.start ? value?.start : ""}
              type="number"
              onChange={(e) =>
                handleChange({ ...value, start: e.target.value })
              }
            />
          </FormControl>
        </div>
        <div style={{ width: "50%" }}>
          <FormControl
            style={{
              width: "100%",
              paddingLeft: "10px",
            }}
            {...otherProps.formControlProps}
          >
            <TextField
              variant="outlined"
              label="Đến"
              size="small"
              fullWidth
              ype="number"
              value={value?.end ? value?.end : ""}
              onChange={(e) => handleChange({ ...value, end: e.target.value })}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
});
const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  errors: {
    "& label": {
      color: "#f44336",
    },
    "& fieldset": {
      borderColor: "#f44336",
    },
  },
  normal: {},
}));
