import { Tooltip } from "@material-ui/core";
import React, { useContext } from "react";
import { gender, is_valid, role_id, sex } from "../../../../common/constants";
import { DevoteeScreen, PersonScreen } from "../../../../common/text.json";
import { AuthContext } from "../../../AuthProvider";
import moment from "moment";

export default function () {
  const { admin } = useContext(AuthContext);
  const columns = [
    {
      field: "name",
      title: DevoteeScreen.name,
      display: true,
      sort: true,
    },
    {
      field: "id_number_2020",
      title: PersonScreen.id_number_2020,
      display: true,
      sort: false,
      render: (row) => {
        return row?.id_number_2020 ? row?.id_number_2020 : "";
      },
    },
    {
      field: "dob_year",
      title: "Năm sinh",
      display: true,
      sort: true,
      render: (row) => <span>{row?.dob_year ? row?.dob_year : "--"}</span>,
    },
    {
      field: "address",
      title: PersonScreen.address,
      display: true,
      sort: false,
      render: (row) => (
        <span>
          {row?.address ? row?.address : "----"},{" "}
          {row?.ward_name ? row?.ward_name : "--"},{" "}
          {row?.district_name ? row?.district_name : "--"},{" "}
          {row?.province_name ? row?.province_name : "--"}
        </span>
      ),
    },
    {
      field: "managed_address",
      title: "Nơi quản lý hồ sơ",
      display: true,
      sort: false,
      render: (row) => (
        <span>
          {row?.managed_ward_name ? row?.managed_ward_name : "--"},{" "}
          {row?.managed_district_name ? row?.managed_district_name : "--"},{" "}
          {row?.managed_province_name ? row?.managed_province_name : "--"}
        </span>
      ),
    },
    {
      field: "devotee",
      title: "Đối tượng NCC",
      display: true,
      sort: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {row?.decision_devotee.map((value, index) => {
            return (
              <Tooltip title={value?.devotee_type?.name}>
                <div
                  key={index}
                  style={{
                    margin: "5px",
                    padding: "3px 8px 3px 8px",
                    borderRadius: "12px",
                    backgroundColor:
                      value?.is_valid == is_valid.YES ? "#E6EFFF" : "gray",
                    color: "#0A65FF",
                    wordBreak: "keep-all",
                  }}
                >
                  {value?.devotee_type?.code}
                </div>
              </Tooltip>
            );
          })}
        </div>
      ),
    },
    {
      field: "updated_at",
      title: "Thời gian cập nhật",
      display: true,
      sort: false,
      render: (row) => (
        <span>{moment(row?.updated_at).format("DD/MM/YYYY hh:mm:ss")}</span>
      ),
    },
  ];
  const filterInputs = [
    {
      field: "name_like",
      type: "text",
      label: DevoteeScreen.name,
      display: true,
      grid: { xs: 12, sm: 6, md: 6 },
    },
    {
      field: "sex_value",
      type: "radio",
      label: PersonScreen.sex,
      options: [
        { label: PersonScreen.male, value: sex.MALE },
        { label: PersonScreen.female, value: sex.FEMALE },
        { label: "Chưa xác định", value: 3 },
      ],
      display: true,
      grid: { xs: 12, sm: 6, md: 6 },
    },
    {
      field: "idNumber",
      type: "text",
      label: PersonScreen.id_numbers,
      display: true,
      grid: { xs: 12, sm: 4, md: 4 },
    },
    {
      field: "bhxh_code_like",
      type: "text",
      label: "Số bảo hiểm y tế",
      display: true,
      grid: { xs: 12, sm: 4, md: 4 },
    },
    {
      field: "decision_issue_number",
      type: "text",
      label: DevoteeScreen.decision_issue_number,
      display: true,
      grid: { xs: 12, sm: 4, md: 4 },
    },
    {
      field: "devotee_type_list",
      type: "devoteeTypeFilter",
      label: DevoteeScreen.devotee_type,
      display: true,
      grid: { xs: 12, sm: 4, md: 4 },
    },
    {
      field: "has_devotee_from",
      type: "number",
      label: DevoteeScreen.has_devotee,
      display: true,
      grid: { xs: 12, sm: 4, md: 4 },
    },
    {
      field: "has_devotee_to",
      type: "number",
      label: "Số lượng đối tượng NCC đến",
      display: true,
      grid: { xs: 12, sm: 4, md: 4 },
    },
    {
      label: "Thời gian cập nhật",
      field: "update_range",
      type: "date-range",
      display: true,
      grid: { xs: 12, sm: 12, md: 12 },
    },
    {
      label: "Tỉ lệ thương tật",
      field: "tltt",
      type: "number-ranger",
      display: true,
      grid: { xs: 12, sm: 12, md: 12 },
    },
    {
      label: "Thâm niên",
      field: "tn",
      type: "number-ranger",
      display: true,
      grid: { xs: 12, sm: 12, md: 12 },
    },
    {
      field: "is_dbn",
      type: "radio",
      options: [
        { label: "Tất cả", value: null },
        { label: "Là đối tượng", value: 2 },
        { label: "Không là đối tượng", value: 1 },
      ],
      label: "Đối tượng có vết thương, bệnh đặc biệt nặng",
      display: true,
      grid: { xs: 12, sm: 12, md: 12 },
      style: 1
    },
    {
      field: "is_npv",
      type: "radio",
      options: [
        { label: "Tất cả", value: null },
        { label: "Là đối tượng", value: 2 },
        { label: "Không là đối tượng", value: 1 },
      ],
      label: "Nhận trợ cấp người phục vụ trực tiếp",
      display: true,
      grid: { xs: 12, sm: 12, md: 12 },
      style: 1
    },
  ];
  const downloadField = [
    {
      name: "Loại đối tượng",
      field: "devotee_type_id",
      table: "devotee_type.name as devotee_type_name",
      check: true,
    },
    {
      name: "Họ tên",
      field: "name",
      table: "personal_infos.name",
      check: true,
    },
    {
      name: "Giới tính",
      field: "sex",
      table: "personal_infos.sex",
      check: true,
    },
    {
      name: "Thời gian cập nhật",
      field: "update_time",
      table: "personal_infos.updated_at",
      check: true,
    },
    {
      name: "Tình trạng",
      field: "is_dead",
      table: "personal_infos.is_dead",
      check: true,
    },
    {
      name: "Ngày sinh",
      field: "dob_date",
      table: "personal_infos.dob_date",
      check: true,
    },
    {
      name: "Tháng sinh",
      field: "dob_month",
      table: "personal_infos.dob_month",
      check: true,
    },
    {
      name: "Năm sinh",
      field: "dob_year",
      table: "personal_infos.dob_year",
      check: true,
    },
    {
      name: "Ngày mất",
      field: "passed_date",
      table: "personal_infos.passed_date",
      check: true,
    },
    {
      name: "Tháng mất",
      field: "passed_month",
      table: "personal_infos.passed_month",
      check: true,
    },
    {
      name: "Năm mất",
      field: "passed_year",
      table: "personal_infos.passed_year",
      check: true,
    },
    {
      name: "Số CMT",
      field: "id_number",
      table: "personal_infos.id_number",
      check: true,
    },
    {
      name: "Căn cước công dân",
      field: "id_number_2020",
      table: "personal_infos.id_number_2020",
      check: true,
    },
    {
      name: "Số điện thoại",
      field: "phone_number",
      table: "personal_infos.phone_number",
      check: true,
    },
    {
      name: "Email",
      field: "email",
      table: "personal_infos.email",
      check: true,
    },
    {
      name: "Dân tộc",
      field: "ethnic_id",
      table: "m_ethnics.name as ethnic_name",
      check: true,
    },
    {
      name: "Tôn giáo",
      field: "religion_id",
      table: "m_religions.name as religion_name",
      check: true,
    },
    {
      name: "Nguyên quán: Tỉnh/Thành phố",
      field: "original_province_id",
      table: "original_province.name as original_province_name",
      check: true,
    },
    {
      name: "Nguyên quán: Quận/Huyện",
      field: "original_district_id",
      table: "original_district.name as original_district_name",
      check: true,
    },
    {
      name: "Nguyên quán: Xã/Phường",
      field: "original_ward_id",
      table: "original_ward.name as original_ward_name",
      check: true,
    },
    {
      name: "Nguyên quán: Địa chỉ chi tiết",
      field: "original_address",
      table: "personal_infos.original_address",
      check: true,
    },
    {
      name: "Trú quán: Tỉnh/Thành phố",
      field: "province_id",
      table: "province.name as province_name",
      check: true,
    },
    {
      name: "Trú quán: Quận/Huyện",
      field: "district_id",
      table: "district.name as district_name",
      check: true,
    },
    {
      name: "Trú quán: Xã/Phường",
      field: "ward_id",
      table: "ward.name as ward_name",
      check: true,
    },
    {
      name: "Trú quán: Địa chỉ chi tiết",
      field: "address",
      table: "personal_infos.address",
      check: true,
    },
    {
      name: "Nơi lưu trữ hồ sơ: Tỉnh/Thành phố",
      field: "managed_province_id",
      table: "managed_province.name as managed_province_name",
      check: true,
    },
    {
      name: "Nơi lưu trữ hồ sơ: Quận/Huyện",
      field: "managed_district_id",
      table: "managed_district.name as managed_district_name",
      check: true,
    },
    {
      name: "Nơi lưu trữ hồ sơ: Xã/Phường",
      field: "managed_ward_id",
      table: "managed_ward.name as managed_ward_name",
      check: true,
    },
  ];
  const province = {
    field: "managed_province_id_equal",
    label: "Tỉnh/Thành phố",
    type: "autocompleteFilter",
    endpoint: "/admin/regions?type=1",
    labelField: "name",
    queryField: "name_like",
    valueField: "id",
    display: true,
    grid: { xs: 12, sm: 4, md: 4 },
  };
  const district = {
    field: "managed_district_id_equal",
    label: "Quận/Huyện",
    type: "autocompleteFilter",
    endpoint: "/admin/regions?type=2",
    params: {
      per_page: 100,
    },
    sub_params: (value) => {
      return {
        parent_region_id_equal:
          admin?.role_id == role_id.DEPARTMENT_PROVINCE
            ? admin?.region_value?.id
            : value?.managed_province_id_equal,
      };
    },
    labelField: "name",
    queryField: "name_like",
    valueField: "id",
    display: true,
    grid: { xs: 12, sm: 4, md: 4 },
  };
  const ward = {
    field: "managed_ward_id_equal",
    label: "Xã/Phường",
    type: "autocompleteFilter",
    endpoint: "/admin/regions?type=3",
    params: {
      per_page: 100,
    },
    sub_params: (value) => {
      return {
        parent_region_id_equal:
          admin?.role_id == role_id.DEPARTMENT_DISTRICT
            ? admin?.region_value?.id
            : value?.managed_district_id_equal,
      };
    },
    labelField: "name",
    queryField: "name_like",
    valueField: "id",
    display: true,
    grid: { xs: 12, sm: 4, md: 4 },
  };
  if (admin?.role_id < role_id.DEPARTMENT_WARD) {
    filterInputs.push({
      label: "Nơi quản lý hồ sơ trong địa bàn quản lý",
      field: "",
      type: "label",
      display: true,
      grid: { xs: 12, sm: 12, md: 12 },
    });
  }
  if (admin?.role_id < role_id.DEPARTMENT_PROVINCE) {
    filterInputs.push(province);
  }
  if (admin?.role_id < role_id.DEPARTMENT_DISTRICT) {
    filterInputs.push(district);
  }
  if (admin?.role_id < role_id.DEPARTMENT_WARD) {
    filterInputs.push(ward);
  }
  filterInputs.push({
    field: "search_all",
    type: "radio",
    label: "Phạm vi tìm kiếm",
    options: [
      { label: "Trên cả nước", value: 1 },
      { label: "Trên địa bàn quản lý", value: null },
    ],
    display: true,
    grid: { xs: 12, sm: 12, md: 12 },
    style: 1
  });
  return { columns, filterInputs, downloadField };
}
