import { Grid, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";
import Autocomplete from "../../form/Autocomplete";

export default function CategoryType({ value, changeValue, hideDevoteeType }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{ fontSize: "12px", color: grey[900], fontWeight: "bold" }}
        >
          {hideDevoteeType != true && value?.devotee_type?.name}
          {hideDevoteeType != true && ": "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          endpoint={value?.devotee_type_input_list?.category_link}
          value={value.value_object ? value.value_object : ""}
          handleChange={(e) => changeValue(e)}
          label={hideDevoteeType != true && value?.input_name}
          queryField={"name_like"}
          labelField={"name"}
        />
      </Grid>
    </Grid>
  );
}
