import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAPI } from "../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";

export default React.memo((props) => {
  const {
    value,
    handleChange,
    label,
    error,
    required,
    params,
    ...otherProps
  } = props;
  const [query, setQuery] = useState();
  const [res, setRes] = useState();
  const api = useAPI();
  const [timeout, setTime] = useState(null);
  const [oldParams, setOldParams] = useState({});
  const endpoint = "admin/welfare_regime?is_for_relative=2";

  useEffect(() => {
    if (JSON.stringify(oldParams) != JSON.stringify(params)) {
      setOldParams(params);
    }
  }, [params]);

  useEffect(() => {
    clearTimeout(timeout);
    setTime(
      setTimeout(() => {
        setRes(
          endpoint
            ? api.fetcher(
                "get",
                endpoint,
                JSON.stringify({ ...query, ...oldParams })
              )
            : null
        );
      }, 200)
    );
  }, [query, endpoint, oldParams]);

  useEffect(() => {
    const _val = value ? { ...value } : null;
    if (_val) {
      if (handleChange) handleChange(_val);
    }
  }, []);

  const options = endpoint ? api?.data?.data || [] : [];

  return (
    <Autocomplete
      size={"small"}
      includeInputInList={true}
      openOnFocus={true}
      loading={api?.loading}
      loadingText={"Đang tải"}
      noOptionsText={"Không có dữ liệu"}
      value={value || null}
      options={options}
      getOptionSelected={(o, v) => o?.id == v?.id}
      getOptionLabel={(option) => option?.wr_type?.name}
      renderOption={(option, state) => {
        return <div style={{ padding: 5 }}>{option?.wr_type?.name}</div>;
      }}
      onChange={(event, value, reason) => {
        handleChange(value);
      }}
      onClose={() => {
        setQuery();
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={otherProps.variant ? otherProps.variant : "outlined"}
          label={label}
          onChange={(event) => setQuery({ id: event.target.value })}
          error={!!error}
          required={required}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {api?.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...otherProps}
    />
  );
});
