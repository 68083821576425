import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Toolbar, Select, MenuItem, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderTop: "1px solid #e0e0e0",
    justifyContent: "space-between",
  },
  pagiLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "30%",
  },
  pagination: {
    "& .Mui-selected": {
      background: "#1976d2",
      color: "#fff",
    },
  },
  total: {
    marginRight: 30,
  },
}));

export default React.memo((props) => {
  const classes = useStyles();
  const {
    setParams,
    page,
    count,
    perPage,
    fromTo,
    total,
    ...otherProps
  } = props;

  const [goPage, setGoPage] = useState(page);
  useEffect(() => {
    setGoPage(page);
  }, [page]);

  return (
    <Toolbar className={classes.root}>
      <div className={classes.pagiLeft}>
        <div
          style={{
            marginRight: 30,
          }}
        >
          <span
            style={{
              marginRight: 10,
            }}
          >
            Số bản ghi
          </span>
          <Select
            value={perPage}
            defaultValue={50}
            onChange={(event) =>
              setParams((params) => ({
                ...params,
                per_page: event.target.value,
                page: 1,
              }))
            }
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={75}>75</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>
        {fromTo ? (
          <div
            className={classes.fromTo}
            style={{
              marginRight: 20,
            }}
          >
            <span>{fromTo[0]}</span> - <span>{fromTo[1]}</span>
          </div>
        ) : (
          ""
        )}
        {total ? <div className={classes.total}>Tổng {total}</div> : ""}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <span
            style={{
              marginRight: 5,
            }}
          >
            Đến trang
          </span>
          <TextField
            type="number"
            InputProps={{
              inputProps: {
                min: 1,
                max: count,
                style: { textAlign: "center", width: 60 },
              },
            }}
            value={goPage}
            onChange={(e) => setGoPage(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                if (goPage > count) {
                  setGoPage(count);
                  setParams((params) => ({
                    ...params,
                    page: count,
                  }));
                } else if (goPage < 1) {
                  setGoPage(1);
                  setParams((params) => ({
                    ...params,
                    page: 1,
                  }));
                } else {
                  setParams((params) => ({
                    ...params,
                    page: goPage,
                  }));
                }
              }
            }}
          />
        </div>
      </div>
      <div className={classes.pagiRight}>
        <Pagination
          className={classes.pagination}
          count={count}
          page={parseInt(page)}
          showFirstButton={true}
          showLastButton={true}
          onChange={(event, page) =>
            setParams((params) => ({ ...params, page: page }))
          }
        />
      </div>
    </Toolbar>
  );
});
