import React, { Fragment, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Date from "./Date";

export default React.memo((props) => {
  const { label, value, onChange } = props;
  const [date, setDate] = useState([]);
  const handleSelect = (value, field) => {
    let temp = [...date];
    if (field == "start") {
      temp[0] = value;
    }
    if (field == "end") {
      temp[1] = value;
    }
    setDate(temp);
    onChange({ startDate: temp?.[0], endDate: temp?.[1] });
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: "12px", color: grey[900], fontWeight: "bold" }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Date
              value={date?.length > 0 ? date?.[0] : ""}
              label="Từ"
              handleChange={(e) => handleSelect(e, "start")}
              size={"small"}
              fullWidth
              format={"DD/MM/YYYY"}
            />
          </Grid>
          <Grid item xs={6}>
            <Date
              disabled={date?.[0] ? false : true}
              minDate={date?.[0]}
              value={date?.length > 0 ? date?.[1] : ""}
              label="Đến"
              handleChange={(e) => handleSelect(e, "end")}
              size={"small"}
              fullWidth
              format={"DD/MM/YYYY"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
});
