import React, { useState, useRef } from "react";
import {
  IconButton,
  Collapse,
  Typography,
  Box,
  SvgIcon,
} from "@material-ui/core";
import ButtonAdd from "../button/ButtonAddSolashi";
import FilterCollapse from "./FilterCollapse";
import FieldCheck from "./FieldCheck";
import { makeStyles } from "@material-ui/core/styles";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import PrintIcon from "@material-ui/icons/Print";
import { useAPI } from "../../api/api";
import { useDialog } from "../Dialog";
import ReactToPrint from "react-to-print";
import "./print.css";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Help } from "../../assets/icons/help.svg";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    border: " 1px solid #036FE3",
    height: "36px",
    minWidth: "120px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#036FE3",
    fontSize: 16,
  },
}));

export default function TableToolbar(props) {
  const {
    loading,
    addButton,
    columns,
    filterInput,
    handleChangeParams,
    checked,
    setChecked,
    endpoint,
    trashed,
    nameFileExport,
    endpointDelete,
    refetch,
    actionDelete,
    showDelete,
    showExport,
    showApprove,
    showPrint,
    initialParams,
    popover,
    noneColumns,
    fileTypeId,
    helperLink,
    showAttribute,
    disableSearch,
    tablePrint,
    showFilter,
    handleCatchReset,
  } = props;

  const ComponentToPrint = React.forwardRef((children, ref) => {
    return (
      <div className="print" ref={ref}>
        {children.children}
      </div>
    );
  });

  const classes = useStyles();
  const api = useAPI();
  const location = useLocation();
  const [viewFilter, setViewFilter] = useState(
    location?.search.split("=").length > 1 ? true : false
  );
  const exportExcel = async (ids) => {
    try {
      let res = await api.fetcher(
        "get",
        endpoint,
        {
          path: "excel File",
          ids: JSON.stringify(ids),
          columns: JSON.stringify(
            columns.columnChecked.filter((column) => column.display == true)
          ),
        },
        {
          responseType: "blob",
        }
      );

      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFileExport + ".xlsx");
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {}
  };

  const history = useHistory();

  const { dialog, handleClose } = useDialog();
  const deleteList = async (ids) => {
    await dialog({
      title: "Xác nhận xóa",
      type: "confirm",
      confirmationText: "Đồng ý",
      cancellationText: "Hủy bỏ",
    });
    try {
      const res = await api.fetcher("post", endpointDelete, {
        ids: JSON.stringify(ids),
        action: actionDelete,
      });
      if (res) {
        setChecked([]);
        refetch();
        handleClose();
      }
    } catch (e) {
      handleClose();
    }
  };

  const ApproveFile = async (ids) => {
    await dialog({
      title: "Xác nhận trả kết quả các hồ sơ đã chọn",
      type: "confirm",
      confirmationText: "Đồng ý",
      cancellationText: "Hủy bỏ",
    });
    try {
      let res = await api.fetcher("post", `/admin/return-list-data`, {
        ids: JSON.stringify(ids),
      });

      if (res) {
        localStorage.setItem("pay_file_tab", 3);
        history.go(0);
      }
    } catch (e) {}
  };

  const componentRef = useRef(null);

  const handleClick = () => {
    setViewFilter(!viewFilter);
  };

  const checkReset = (value) => {
    handleCatchReset ? handleCatchReset(value) : console.log("reset");
  };

  return (
    <Box
      style={{
        marginBottom: 8,
        backgroundColor: "#FAFAFA",
        borderRadius: 8,
        padding: 16,
        minHeight: 68,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "36px",
        }}
      >
        {showFilter != false && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            {viewFilter ? (
              <ExpandLess style={{ fill: "#036FE3" }} />
            ) : (
              <ExpandMore style={{ fill: "#036FE3" }} />
            )}

            <Typography
              style={{
                color: "#036FE3",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Tìm kiếm
            </Typography>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
          }}
        >
          {addButton?.render ? (
            addButton.render
          ) : addButton?.display ? (
            <ButtonAdd
              text={addButton?.text}
              onClick={() => addButton.onClick()}
            />
          ) : (
            <div></div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            {checked?.length > 0 && showExport != false && (
              <div
                className={classes.button}
                onClick={() => {
                  exportExcel(checked);
                }}
              >
                <SvgIcon
                  style={{ marginTop: 8 }}
                  viewBox="0 0 24 24"
                  component={Download}
                />
                Xuất excel
              </div>
            )}
            {checked?.length > 0 && showApprove == true && (
              <div
                className={classes.button}
                onClick={() => {
                  ApproveFile(checked);
                }}
              >
                Trả kết quả
              </div>
            )}
            {checked?.length > 0 && showDelete == true && (
              <SvgIcon
                style={{ fill: "#036FE3", cursor: "pointer" }}
                viewBox="0 0 18 18"
                component={Delete}
                onClick={() => {
                  deleteList(checked);
                }}
              />
            )}
            {checked?.length > 0 && showPrint && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <IconButton title={"In dữ liệu"}>
                      <PrintIcon />
                    </IconButton>
                  )}
                  content={() => componentRef.current}
                />

                <ComponentToPrint
                  ref={componentRef}
                  children={tablePrint?.render}
                />
              </>
            )}

            {/* filter */}
            {/* {popover ? (
              <Filter
                handleChangeParams={handleChangeParams}
                inputs={filterInput}
                loading={loading}
                initialParams={initialParams}
              />
            ) : disableSearch != true ? (
              <IconButton
                onClick={() => setViewFilter((pre) => !pre)}
                title={"Lọc dữ liệu"}
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <div />
            )} */}
            {columns && !noneColumns && <FieldCheck columns={columns} />}
            {trashed?.display ? (
              trashed?.isTrash ? (
                <IconButton
                  title={"Quay lại"}
                  onClick={() =>
                    handleChangeParams((pre) => ({
                      ...pre,
                      only_trashed: undefined,
                    }))
                  }
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              ) : (
                <IconButton
                  title={"Thùng rác"}
                  onClick={() =>
                    handleChangeParams((pre) => ({
                      ...pre,
                      only_trashed: true,
                    }))
                  }
                >
                  <DeleteSweepIcon />
                </IconButton>
              )
            ) : null}
            {helperLink && (
              <SvgIcon
                style={{ marginTop: 2, fill: "#036FE3", cursor: "pointer" }}
                viewBox="0 0 22 22"
                component={Help}
                onClick={() => {
                  window.open(helperLink, "_blank");
                }}
              />
            )}
          </div>
        </div>
      </div>

      <Collapse
        in={viewFilter}
        timeout="auto"
        unmountOnExit
        style={{ marginTop: 16 }}
      >
        <FilterCollapse
          fileTypeId={fileTypeId}
          handleChangeParams={handleChangeParams}
          inputs={columns?.filterFieldChecked}
          handleChangeFilterFields={columns?.handleChangeFilterFields}
          loading={loading}
          initialParams={initialParams}
          reset={columns?.defaultFilter}
          setHide={setViewFilter}
          showAttribute={showAttribute}
          handleCatchReset={checkReset}
        />
      </Collapse>
    </Box>
  );
}
