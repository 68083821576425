import React from "react";
import "./App.css";
import AuthProvider from "./containers/AuthProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import DialogProvider from "./components/Dialog";
import MainRouter from "./routes/MainRouter";
import "./scrollbar.css";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#036FE3",
    },
    custom: {
      primary: "#036FE3",
      secondary: "#4c9aff",
      background: "#F7F7F7",
      error: "#F0A69B",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
  },
});
function App() {
  document.head.querySelector('meta[name="description"]').content =
    "Cục Người có công - Bộ Lao động - Thương binh và Xã hội\nPhần mềm Cơ sở dữ liệu Người có công với cách mạng";
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DialogProvider>
          <MainRouter />
        </DialogProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
