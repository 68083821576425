import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAPI } from "../../api/api";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyle = makeStyles(() => ({
  input: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 20,
  },
}));

export default function FormResetPassword(props) {
  const classes = useStyle();
  const api = useAPI();
  const history = useHistory();
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      checkTokenPassword();
    } else {
      history.push({
        pathname: "/login",
      });
    }
    async function checkTokenPassword() {
      try {
        const check_token = await api.fetcher("post", "/check-token-password", {
          token,
        });
        if (!check_token) {
          history.push({
            pathname: "/login",
          });
        }
      } catch (error) {
        history.push({
          pathname: "/login",
        });
      }
    }
  }, [token]);
  const formik = useFormik({
    initialValues: {
      password: "",
      token: token,
    },
    validationSchema: Yup.object().shape({
      // Validate form field
      password: Yup.string()
        .min(6, "Mật khẩu có ít nhất 6 kí tự")
        .max(32, "Mật khẩu có nhiều nhất 32 kí tự")
        .required("Bạn hãy nhập mật khẩu mới")
        .nullable(),
    }),
    onSubmit: async (values) => {
      try {
        const res = await api.fetcher("post", "/setNewPassword", values);
        if (res) {
          history.push("/login");
        }
      } catch (e) {}
    },
  });

  return (
    <form style={{ padding: 10 }}>
      <Grid className={classes.input}>
        <Input
          className="input1"
          type="password"
          value={formik.values?.password}
          onChange={(e) => formik.setFieldValue("password", e.target.value)}
          error={
            (formik.errors?.password && formik.touched.password) ||
            api.error?.password
          }
          id="input-with-icon-adornment"
          placeholder={"Nhập mật khẩu mới"}
          startAdornment={
            <InputAdornment position="start">
              <LockOutlinedIcon style={{ fontSize: 25 }} color="action" />
            </InputAdornment>
          }
        />
        <FormHelperText error={true}>
          {(formik.touched.password && formik.errors?.password) ||
            api.error?.password}
        </FormHelperText>
      </Grid>
      <Grid className={classes.button}>
        <Button
          onClick={formik.handleSubmit}
          color="primary"
          variant="contained"
          style={{ marginBottom: 10, width: "100%", borderRadius: 10 }}
        >
          Thay đổi mật khẩu
        </Button>
      </Grid>
    </form>
  );
}
