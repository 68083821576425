import React, { useMemo } from "react";
import { useAPI } from "../../api/api";
import Forms from "../../components/form/Form";
import { useFormik } from "formik";
import { Button, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { loadingStyle } from "../../common/constants";
import { ErrorText, ChangePasswordScreen } from "../../common/text.json";

export default function (props) {
  const classes = useStyles();
  const api = useAPI();
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      renew_password: "",
    },
    onSubmit: async (values) => {
      try {
        const res = await api.fetcher("post", "admin/changePassword", {
          old_password: values?.old_password,
          new_password: values?.new_password,
        });
        await props?.close();
      } catch (e) {
        formik.setErrors(e?.data?.error);
      }
    },
    validationSchema: Yup.object().shape({
      // Validate form field
      old_password: Yup.string()
        .required(ChangePasswordScreen.old_password + " " + ErrorText.required)
        .max(
          32,
          ChangePasswordScreen.old_password +
            " " +
            ErrorText.max_before +
            " " +
            32 +
            " " +
            ErrorText.max_after
        ),
      new_password: Yup.string()
        .required(ChangePasswordScreen.new_password + " " + ErrorText.required)
        .max(
          32,
          ChangePasswordScreen.new_password +
            " " +
            ErrorText.max_before +
            " " +
            32 +
            " " +
            ErrorText.max_after
        )
        .min(
          6,
          ChangePasswordScreen.new_password +
            " " +
            ErrorText.min_before +
            " " +
            6 +
            " " +
            ErrorText.min_after
        )
        .matches(
          /(?=.*[A-Za-z])(?=.*\d)/,
          "Có ít nhất 1 chữ hoa, 1 chữ thường và 1 số"
        )
        .trim(),
      renew_password: Yup.string()
        .required(
          ChangePasswordScreen.renew_password + " " + ErrorText.required
        )
        .max(
          32,
          ChangePasswordScreen.renew_password +
            " " +
            ErrorText.max_before +
            " " +
            32 +
            " " +
            ErrorText.max_after
        )
        .oneOf(
          [Yup.ref("new_password")],
          ChangePasswordScreen.prepare_password
        ),
    }),
  });

  const inputs = useMemo(
    () => [
      [
        {
          label: ChangePasswordScreen.old_password,
          value: formik.values?.old_password,
          variant: "standard",
          handleChange: (e) => formik.setFieldValue("old_password", e),
          type: "password",
          error: formik.touched.old_password && formik?.errors?.old_password,
          grid: { xs: 12, sm: 12, md: 12 },
        },
        {
          label: ChangePasswordScreen.new_password,
          value: formik.values?.new_password,
          variant: "standard",
          handleChange: (e) => formik.setFieldValue("new_password", e),
          type: "password",
          error: formik.touched.new_password && formik?.errors?.new_password,
          grid: { xs: 12, sm: 12, md: 12 },
        },
        {
          label: ChangePasswordScreen.renew_password,
          value: formik.values?.renew_password,
          variant: "standard",
          handleChange: (e) => formik.setFieldValue("renew_password", e),
          error:
            formik.touched.renew_password && formik?.errors?.renew_password,
          type: "password",
          grid: { xs: 12, sm: 12, md: 12 },
        },
      ],
    ],
    [formik]
  );
  return (
    <div className={api.loading ? classes.loading : classes.action}>
      <Forms inputs={inputs} />
      <Button
        color="primary"
        onClick={formik.handleSubmit}
        variant={"contained"}
      >
        Cập nhật
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  action: {
    paddingLeft: 10,
  },
  loading: loadingStyle,
}));
