import React, { useEffect, useState } from "react";
import { TextField, Typography, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { grey } from "@material-ui/core/colors";

export default function ListType({ value, changeValue, hideDevoteeType }) {
  const [option, setOption] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);
  useEffect(() => {
    if (value?.devotee_type_input_list?.list && firstLoading == true) {
      setOption(value?.devotee_type_input_list?.list.split(","));
      setFirstLoading(false);
    }
  }, [value, firstLoading]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{ fontSize: "12px", color: grey[900], fontWeight: "bold" }}
        >
          {hideDevoteeType != true && value?.devotee_type?.name}
          {hideDevoteeType != true && ": "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          value={value.value ? value.value : ""}
          options={option}
          onChange={(event, value, reason) => {
            changeValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={hideDevoteeType != true && value?.input_name}
              variant={"outlined"}
              size={"small"}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
