import React from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Tooltip, Typography } from "@material-ui/core";

export default function NumberType({
  value,
  changeValue,
  changeNumberType,
  index,
  hideDevoteeType,
}) {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            fontSize: "12px",
            fontWeight: 800,
            color: "#17488a",
          }}
        >
          <Tooltip title={value?.input_name}>
            <span
              style={{
                fontSize: "12px",
                borderRadius: "50%",
                border: "blue solid 1px",
                cursor: "pointer",
                marginRight: "5px",
                width: "16px",
                height: "16px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                color: "blue",
              }}
            >
              i
            </span>
          </Tooltip>
          {value?.input_name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={value.start ? value.start : ""}
          onChange={(e) => changeNumberType(e.target.value, index, "start")}
          size={"small"}
          variant={"outlined"}
          type={"number"}
          fullWidth
          label="Từ"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={value.end ? value.end : ""}
          onChange={(e) => changeNumberType(e.target.value, index, "end")}
          size={"small"}
          variant={"outlined"}
          type={"number"}
          fullWidth
          label="Đến"
        />
      </Grid>
    </Grid>
  );
}
