import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import { Grid } from "@material-ui/core";

export default function TextType(props) {
  const { value, changeValue, hideDevoteeType } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{ fontSize: "12px", color: grey[900], fontWeight: "bold" }}
        >
          {hideDevoteeType != true && value?.devotee_type?.name}
          {hideDevoteeType != true && ": "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          size={"small"}
          fullWidth
          variant={"outlined"}
          value={value.value ? value.value : ""}
          label={hideDevoteeType != true && value?.input_name}
          onChange={(e) => changeValue(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
