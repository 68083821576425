import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { FlatForms } from "../form/Form";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Collapse,
  Box,
  SvgIcon,
} from "@material-ui/core";
import Popover from "../popover";
import Button from "../button/ButtonSolashi";
import FilterAttribute from "./FilterAttribute/FilterAttribute";
import { useFetch } from "../../api/api";
import { ReactComponent as Quotes } from "../../assets/icons/quotes.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";

const FilterCollapse = React.memo((props) => {
  const classes = useStyles();
  const {
    inputs,
    loading,
    handleChangeParams,
    initialParams,
    handleChangeFilterFields,
    reset,
    setHide,
    fileTypeId,
    showAttribute,
    handleCatchReset,
  } = props;
  const [attribute, setAttribute] = useState([]);
  const [devoteeType, setDevoteeType] = useState([]);
  const [arrayCheck, setArrayCheck] = useState([]);
  const [reload, setReload] = useState(false);
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) =>
      handleChangeParams((pre) => ({
        ...pre,
        ...values,
        page: 1,
        attribute: JSON.stringify(
          attribute.filter((value) => value.display == true)
        ),
      })),
  });
  useEffect(() => {
    const formikKeys = Object.keys(formik.values);
    const inputKeys = inputs.map((input) => input.field);
    for (let key of formikKeys) {
      if (!inputKeys.includes(key)) formik.setFieldValue(key, null);
    }
  }, [inputs]);
  const handleReset = () => {
    let tempArray = [...attribute];
    tempArray.forEach((value) => {
      delete value.value;
      delete value.value_object;
      delete value.start;
      delete value.end;
    });
    setAttribute(tempArray);
    setReload(true);
    formik.setValues({});
    handleChangeParams((pre) =>
      initialParams
        ? {
            ...initialParams,
            tab: pre?.tab,
          }
        : {
            per_page: pre?.per_page,
            page: 1,
            tab: pre?.tab,
          }
    );
    handleCatchReset(true);
  };

  const { data } = useFetch(
    showAttribute ? ["get", "/admin/getAllAttribute"] : []
  );

  const typeData = {
    text: 1,
    number: 2,
    date: 3,
    list: 4,
    category: 5,
  };
  useEffect(() => {
    if (data) {
      let tempArray = [...arrayCheck];
      let tempDevoteeType = [...devoteeType];
      data.forEach((value) => {
        if (!tempArray.includes(value.devotee_type_id)) {
          tempArray.push(value.devotee_type_id);
          value.open = false;
          tempDevoteeType.push(value);
        }
      });
      setArrayCheck(tempArray);
      setDevoteeType(tempDevoteeType);
      setAttribute(
        data.map((value) => {
          if (
            value.value_type == typeData.number ||
            value.value_type == typeData.date
          ) {
            value.start = 0;
            value.end = 0;
          }
          value.display = false;
          return value;
        })
      );
    }
  }, [data]);

  const handleChangeDisplayAttribute = (value, index) => {
    let temp = [...attribute];
    temp[index].display = value;
    setAttribute(temp);
  };

  const resetDisplayAttribute = () => {
    let temp = [...attribute];
    temp.map((value) => (value.display = false));
    setAttribute(temp);
  };

  const openCollapse = (index) => {
    let temp = [...devoteeType];
    temp[index].open = temp[index].open == true ? false : true;
    setDevoteeType(temp);
  };

  const FieldCheck = useMemo(() => {
    return (
      <div className={classes.root} style={{ padding: "10px", width: "auto" }}>
        <div style={{ marginBottom: 10 }}>
          <Button
            variant="contained"
            onClick={() => {
              reset();
              resetDisplayAttribute();
            }}
          >
            Mặc định
          </Button>
        </div>
        <Grid container>
          {inputs?.map((field, index) => (
            <Grid item xs={6} key={index}>
              <FormControlLabel
                key={index}
                value={field.display}
                control={<Checkbox color="primary" checked={field.display} />}
                label={field.label}
                onChange={(event) => {
                  formik.setFieldValue(field?.field, null);
                  handleChangeFilterFields(field);
                }}
              />
            </Grid>
          ))}
          {attribute.length > 0 && showAttribute == true && (
            <Grid
              item
              xs={12}
              style={{
                borderBottom: "1px solid #e0e0e0",
                marginBottom: "8px",
              }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                  lineHeight: "18px",
                  fontWeight: 800,
                }}
              >
                Thông tin bổ sung
              </Typography>
            </Grid>
          )}
          <Grid container>
            {devoteeType.map((devotee, indexDevotee) => {
              return (
                showAttribute == true && (
                  <Grid container style={{ width: "100%" }} key={indexDevotee}>
                    <Grid
                      item
                      xs={12}
                      className={classes.collapdeContainer}
                      onClick={() => openCollapse(indexDevotee)}
                    >
                      <div onClick={() => openCollapse(indexDevotee)}>
                        {devotee?.open == true ? (
                          <SvgIcon
                            component={Minus}
                            style={{ fill: "#A0A0A0", marginTop: 10 }}
                          />
                        ) : (
                          <SvgIcon
                            component={Add}
                            style={{ fill: "#A0A0A0", marginTop: 10 }}
                          />
                        )}
                      </div>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "16px",
                          fontWeight: 800,
                        }}
                      >
                        {devotee?.devotee_type?.name}{" "}
                      </Typography>
                    </Grid>
                    <Collapse
                      in={devotee?.open}
                      timeout="auto"
                      unmountOnExit
                      style={{ width: "100%" }}
                    >
                      <Grid container style={{ width: "100%" }}>
                        {attribute.map((data, index) => {
                          return (
                            devotee?.devotee_type_id ==
                              data?.devotee_type_id && (
                              <Grid item xs={4} key={index}>
                                <FormControlLabel
                                  key={index}
                                  value={data.display}
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={data.display}
                                    />
                                  }
                                  label={data?.input_name}
                                  onChange={(event) => {
                                    handleChangeDisplayAttribute(
                                      !data.display,
                                      index
                                    );
                                  }}
                                />
                              </Grid>
                            )
                          );
                        })}
                      </Grid>
                    </Collapse>
                  </Grid>
                )
              );
            })}
          </Grid>
        </Grid>
      </div>
    );
  }, [inputs, attribute, devoteeType]);

  const Content = useMemo(() => {
    return (
      <Box>
        <FlatForms
          formik={formik}
          inputs={inputs.map((e) =>
            e.display == true ? e : { ...e, gridStyle: { display: "none" } }
          )}
        />
        {(fileTypeId || showAttribute) == true && (
          <div style={{ marginBottom: "10px" }}>
            <FilterAttribute
              fileTypeId={fileTypeId}
              attribute={attribute}
              setAttribute={setAttribute}
              reload={reload}
              setReload={setReload}
              showAttribute={showAttribute}
              data={data}
            />
          </div>
        )}
        <div className={classes.buttons}>
          <Popover
            content={FieldCheck}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            children={
              <div
                style={{
                  minWidth: "180px",
                }}
                className={classes.buttonFilter}
                disabled={loading}
                loading={loading}
              >
                <SvgIcon
                  style={{ marginTop: 8 }}
                  viewBox="0 0 24 24"
                  component={Quotes}
                />
                Tùy chọn nâng cao
              </div>
            }
          />
          <div
            style={{
              minWidth: "110px",
            }}
            className={classes.buttonFilter}
            onClick={handleReset}
            disabled={loading}
            loading={loading}
          >
            <SvgIcon
              style={{ marginTop: 8 }}
              viewBox="0 0 24 24"
              component={Refresh}
            />
            Làm mới
          </div>
          <div
            style={{
              border: " 1px solid #036FE3",
              backgroundColor: "#036FE3",
              height: "36px",
              minWidth: 110,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "#fff",
              fontSize: 16,
            }}
            onClick={formik.handleSubmit}
            disabled={loading}
            loading={loading}
          >
            <SvgIcon
              style={{ marginTop: 8 }}
              viewBox="0 0 24 24"
              component={Search}
            />
            Tìm kiếm
          </div>
        </div>
      </Box>
    );
  }, [formik, inputs]);

  return <React.Fragment>{Content}</React.Fragment>;
});
const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  filter: {
    // width: 380,
    border: "1px solid #000",
    maxHeight: 550,
    padding: "15px 18px",
    overflowY: "auto",
  },
  buttons: {
    display: "flex",
    paddingTop: "8px",
    direction: "row",
    gap: 8,
  },
  btnMargin: {
    padding: "6px 16px",
    marginLeft: 10,
  },
  root: {
    minWidth: 500,
    padding: 10,
  },
  hideBtnContainer: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
    height: 30,
  },
  hideBtn: {
    top: -10,
    right: -14,
    position: "absolute",
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      background: "#f3f3f3",
    },
  },
  collapdeContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#f3f3f3",
    },
  },
  buttonFilter: {
    border: " 1px solid #036FE3",
    height: "36px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#036FE3",
    fontSize: 16,
  },
}));
export default FilterCollapse;
