import React, { useMemo, useState, useEffect } from "react";
import Autocomplete from "./Autocomplete";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default React.memo((props) => {
  const {
    type,
    label,
    value,
    handleChange,
    error,
    size,
    ...otherProps
  } = props;
  const classes = useStyle();
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [ward, setWard] = useState();
  const provinceComponent = useMemo(() => {
    return (
      <Autocomplete
        endpoint="admin/regions?type=1&per_page=100"
        queryField={"name"}
        label={"Tỉnh thành"}
        value={province}
        size={size}
        handleChange={(value) => {
          setProvince(value);
          setDistrict("");
          setWard("");
        }}
        error={error?.province_id}
      />
    );
  }, [error, province, value]);

  const districtComponent = useMemo(() => {
    const id = province?.id;

    return (
      <Autocomplete
        endpoint={
          id
            ? "admin/regions?type=2&per_page=100&parent_region_id_equal=" + id
            : null
        }
        queryField={"name"}
        label={"Quận huyện"}
        value={district}
        size={size}
        handleChange={(value) => {
          setDistrict(value);
          setWard("");
        }}
        error={error?.district_id}
      />
    );
  }, [error, district, value]);

  const wardComponent = useMemo(() => {
    const id = district?.id;
    return (
      <Autocomplete
        endpoint={
          id
            ? "admin/regions?type=3&per_page=100&parent_region_id_equal=" + id
            : null
        }
        queryField={"name"}
        label={"Phường xã"}
        value={ward}
        size={size}
        handleChange={(value) => {
          setWard(value);
        }}
        error={error?.ward_id}
      />
    );
  }, [error, ward, value]);
  useEffect(() => {
    if (ward) {
      handleChange(ward?.id);
    } else if (district) {
      handleChange(district?.id);
    } else if (province) {
      handleChange(province?.id);
    } else {
      handleChange(undefined);
    }
  }, [province, district, ward]);

  useEffect(() => {
    if (typeof value == "undefined") {
      setProvince("");
      setDistrict("");
      setWard("");
    }
  }, [value]);

  return (
    <Grid container>
      <Grid className={classes.province} item sm={4} md={4}>
        {provinceComponent}
      </Grid>
      <Grid className={classes.district} item sm={4} md={4}>
        {districtComponent}
      </Grid>
      <Grid className={classes.ward} item sm={4} md={4}>
        {wardComponent}
      </Grid>
    </Grid>
  );
});

const useStyle = makeStyles((theme) => ({
  province: {
    paddingRight: "10px",
  },
  district: {
    padding: "0 5px",
  },
  ward: {
    paddingLeft: "10px",
  },
}));
