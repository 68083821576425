import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAPI } from "../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";

export default React.memo((props) => {
  const {
    endpoint,
    queryField,
    valueField,
    labelField,
    value,
    handleChange,
    label,
    error,
    required,
    params,
    customField,
    ...otherProps
  } = props;
  const [query, setQuery] = useState();
  const [res, setRes] = useState();
  const api = useAPI();
  const [timeout, setTime] = useState(null);
  const [oldParams, setOldParams] = useState({});

  useEffect(() => {
    if (JSON.stringify(oldParams) != JSON.stringify(params)) {
      setOldParams(params);
    }
  }, [params]);

  const [checkClick, setCheckClick] = useState(false);

  useEffect(() => {
    clearTimeout(timeout);
    setTime(
      setTimeout(() => {
        setRes(
          endpoint && checkClick == true
            ? api.fetcher(
                "get",
                endpoint,
                JSON.stringify({ ...query, ...oldParams })
              )
            : null
        );
      }, 200)
    );
  }, [query, endpoint, oldParams, checkClick]);
  useEffect(() => {
    const _val = value ? { ...value } : null;
    if (_val) {
      if (handleChange) handleChange(_val);
    }
  }, []);

  const options = endpoint ? api?.data?.data || [] : [];

  return (
    <div onFocus={() => setCheckClick(true)} style={{ width: "100%" }}>
      <Autocomplete
        size={"small"}
        includeInputInList={true}
        openOnFocus={true}
        loading={api?.loading}
        loadingText={"Đang tải"}
        noOptionsText={"Không có dữ liệu"}
        value={value || null}
        options={options}
        getOptionSelected={(o, v) => o?.id == v?.id}
        getOptionLabel={(option) => option?.[labelField || queryField || ""]}
        renderOption={(option, state) => {
          return customField ? (
            <div style={{ padding: 5 }}>
              <span style={{ fontWeight: 800 }}>
                {option?.[labelField ? labelField : queryField]
                  ? option?.[labelField ? labelField : queryField]
                  : null}
              </span>
              <span style={{ fontStyle: "italic" }}>
                {option?.[customField ? customField : customField]
                  ? " (" +
                    option?.[customField ? customField : customField] +
                    ")"
                  : null}
              </span>
            </div>
          ) : (
            <div style={{ padding: 5 }}>
              {option?.[labelField ? labelField : queryField]
                ? option?.[labelField ? labelField : queryField]
                : null}
            </div>
          );
        }}
        onChange={(event, value, reason) => {
          handleChange(value);
        }}
        onClose={() => {
          setQuery();
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={otherProps.variant ? otherProps.variant : "outlined"}
            label={label}
            onChange={(event) => setQuery({ [queryField]: event.target.value })}
            error={!!error}
            required={required}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {api?.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...otherProps}
      />
    </div>
  );
});
