import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { AuthContext } from "../AuthProvider";
import Popover from "../../components/popover";
import PersonIcon from "@material-ui/icons/Person";
import InfoIcon from "@material-ui/icons/Info";
import LockIcon from "@material-ui/icons/Lock";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useDialog } from "../../components/Dialog";
import { useHistory } from "react-router-dom";
import color from "../../common/color.json";
import { useAPI, useFetch } from "../../api/api";
import ChangePassword from "./ChangePassword";
import UpdateInfo from "./UpdateInfo";
import quoc_huy from "../../assets/image/Quoc_huy.png";
import { HeaderScreen } from "../../common/text.json";
import { Grid, Typography } from "@material-ui/core";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: color.primary,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftBar: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumbs: {
    paddingLeft: 30,
  },
  menuButton: {
    marginRight: 36,
  },
  icon: {
    justifyContent: "flex-end",
  },
  rightBar: {
    display: "flex",
    "& svg": {
      fontSize: "2rem",
      width: "2rem",
      height: "2rem",
    },
    border: "1px solid #fff",
    borderRadius: "4px",
    padding: "6px",
    minWidth: 180,
    cursor: "pointer",
  },
  showHeader: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { dialog, handleClose } = useDialog();
  const { admin, user, clear } = useContext(AuthContext);
  const [configCssData, setConfigCssData] = useState(null);
  const name = () => {
    if (admin) {
      return admin.name;
    }
    return null;
  };
  const api = useAPI();
  const history = useHistory();
  const logout = async () => {
    if (admin) {
      try {
        let res = await api.fetcher("post", "/admin/logout");
        if (res) {
          clear();
          localStorage.removeItem("infoMe");
          localStorage.removeItem("infoRole");
          localStorage.removeItem("last_time");
          history.push("/login");
        }
      } catch (e) {
        console.log({ e });
      }
    }
    if (user) {
      clear();
      history.push("/login");
    }
  };
  const changePassword = async () => {
    await dialog({
      title: HeaderScreen.change_password,
      content: <ChangePassword close={handleClose} />,
    });
  };
  const changeInfo = async () => {
    await dialog({
      title: HeaderScreen.change_info,
      content: <UpdateInfo close={handleClose} />,
    });
  };
  const userItems = () => {
    return (
      <List>
        {!user && (
          <ListItem button={true} onClick={() => changeInfo()}>
            <ListItemText primary={HeaderScreen.change_info} />
            <ListItemIcon className={classes.icon}>
              <InfoIcon />
            </ListItemIcon>
          </ListItem>
        )}
        {!user && (
          <ListItem button={true} onClick={() => changePassword()}>
            <ListItemText primary={HeaderScreen.change_password} />
            <ListItemIcon className={classes.icon}>
              <LockIcon />
            </ListItemIcon>
          </ListItem>
        )}
        <ListItem button={true} onClick={() => logout()}>
          <ListItemText primary={HeaderScreen.logout} />
          <ListItemIcon className={classes.icon}>
            <PowerSettingsNewIcon />
          </ListItemIcon>
        </ListItem>
      </List>
    );
  };
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (window.screen.width > 1000) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [window.screen.width]);
  const configCss = localStorage.getItem("configCss");
  const { data: config } = useFetch(
    !configCss && ["get", user ? "/people/display/1" : "/admin/display/1"]
  );

  useEffect(() => {
    if (configCss) {
      setConfigCssData(JSON.parse(configCss));
    } else {
      if (config) {
        setConfigCssData(config);
        localStorage.setItem("configCss", JSON.stringify(config));
      }
    }
  }, [config, configCss]);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
      >
        <Toolbar
          className={classes.toolbar}
          style={
            configCssData?.header_type == 2
              ? {
                  height: "80px",
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_UPLOAD_IMAGE_URL +
                    "/storage/" +
                    configCssData?.header_image +
                    ")",
                  padding: "32px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                }
              : {
                  height: "80px",
                  backgroundColor: configCssData?.header_color
                    ? configCssData?.header_color
                    : "#bae0fc",
                  padding: "32px",
                }
          }
        >
          <div className={classes.leftBar}>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
            >
              {props.open ? (
                <ArrowBackIcon style={{ color: "black" }} />
              ) : (
                <MenuIcon style={{ color: "black" }} />
              )}
            </IconButton> */}
            {show == true && (
              <div
                style={{
                  fontSize: "1.142rem",
                  color: "#373737",
                  textTransform: "uppercase",
                  display: "flex",
                  flexWrap: "wrap",
                  // padding: "20px",
                  // textShadow: '0.5px 0.5px 2px black',
                }}
                className={classes.showHeader}
              >
                <img
                  src={quoc_huy}
                  height={70}
                  width={"auto"}
                  style={{ margin: "auto" }}
                  alt="quoc huy"
                />
                <div style={{ height: "80", paddingLeft: 20 }}>
                  <p
                    style={{
                      fontSize: "1rem",
                      margin: 0,
                      position: "absolute",
                      fontWeight: 600,
                      color: configCssData?.header_text_top_color
                        ? configCssData?.header_text_top_color
                        : "#000000",
                    }}
                  >
                    {configCssData?.header_text_top
                      ? configCssData?.header_text_top
                      : HeaderScreen.text_header_top}
                  </p>
                  <br />
                  <p
                    style={{
                      fontSize: "1.8rem",
                      margin: 0,
                      position: "absolute",
                      fontWeight: "bold",
                      color: configCssData?.header_text_bottom_color
                        ? configCssData?.header_text_bottom_color
                        : "#17488a",
                    }}
                  >
                    {configCssData?.header_text_bottom
                      ? configCssData?.header_text_bottom
                      : HeaderScreen.text_header_bottom}
                  </p>
                </div>
                <b></b>
              </div>
            )}
          </div>
          <div className={classes.rightBar}>
            <div />
            <Popover
              children={
                <Grid container direction="row" justifyContent="center">
                  <PersonIcon style={{ width: 24, height: 24 }} />

                  <Typography style={{ marginLeft: 8 }}>
                    {admin?.full_name}
                  </Typography>
                </Grid>
              }
              content={userItems()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
