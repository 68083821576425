import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyB9WhleR7rPfJkhLaRcpSWAd39CO5DCpS8",
  authDomain: "ncc-develop.firebaseapp.com",
  projectId: "ncc-develop",
  storageBucket: "ncc-develop.appspot.com",
  messagingSenderId: "998769184951",
  appId: "1:998769184951:web:0f64d36df8a86c75af3d32",
  measurementId: "G-RHWZ926R9E",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };
