import React, { useState, useMemo } from "react";
import { useFetch } from "../../../../api/api";
import TableToolbar from "../../../../components/table/TableToolbar";
import useCheckedColumns from "../../../../common/CheckedColumns";
import Input from "../devoteeInfo/Input";
import Pagination from "../../../../components/table/Pagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { gender } from "../../../../common/constants";

export default function List(props) {
  const initialParams = {
    per_page: 50,
    page: 1,
    list: "is_devotee",
    is_devotee_equal: 2,
    is_devotee: 2
  };

  const [params, setParams] = useState(initialParams);
  const _params = useMemo(() => params, [params]);

  const { data: devotee, loading } = useFetch([
    "get",
    "/admin/personal-information",
    JSON.stringify(_params),
  ]);
  const { columns, filterInputs } = Input();
  const columnCheck = useCheckedColumns({
    columns: columns,
    filterFields: filterInputs,
  });
  const { handleSelect, close, relativeId } = props;
  const data = devotee?.data.filter((value) => value.id != relativeId);

  return (
    <React.Fragment style={{ width: "auto" }}>
      <TableToolbar
        addButton={{}}
        columns={columnCheck}
        filterInput={filterInputs}
        handleChangeParams={setParams}
        loading={loading}
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Họ tên</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Số CMT/CCCD</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Ngày sinh</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Giới tính</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Địa chỉ</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Số điện thoại
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
            </TableRow>
          </TableHead>
          {devotee
            ? data.length != 0
              ? data.map((el) => (
                  <TableBody
                    onClick={() => {
                      handleSelect(el);
                      close();
                    }}
                  >
                    <TableRow key={el?.name}>
                      <TableCell component="th" scope="row">
                        {el?.name}
                      </TableCell>
                      <TableCell>
                        {el?.id_number_2020
                          ? el?.id_number_2020
                          : el?.id_number}
                      </TableCell>
                      <TableCell>
                        {(el?.dob_date ? el?.dob_date : "--") +
                          "/" +
                          (el?.dob_month ? el?.dob_month : "--") +
                          "/" +
                          (el?.dob_year ? el?.dob_year : "----")}
                      </TableCell>
                      <TableCell>{el?.sex == gender.Nam ? "Nam" : "Nữ"}</TableCell>
                      <TableCell>
                        {(el?.address ? el?.address + "-" : "") +
                          (el?.ward_id?.name ? el?.ward_id?.name + "-" : "") +
                          (el?.district_id?.name
                            ? el?.district_id?.name + "-"
                            : "") +
                          (el?.province_id?.name ? el?.province_id?.name : "")}
                      </TableCell>
                      <TableCell>{el?.phone_number}</TableCell>
                      <TableCell>{el?.email}</TableCell>
                    </TableRow>
                  </TableBody>
                ))
              : ""
            : ""}
        </Table>
      </TableContainer>
      <Pagination
        setParams={setParams}
        count={devotee?.last_page}
        page={params?.page}
        perPage={params.per_page}
        fromTo={[devotee?.from, devotee?.to]}
        total={devotee?.total}
      />
    </React.Fragment>
  );
}
