import React, { useState, useEffect } from "react";
import {
  TextField,
  Popper,
  Paper,
  ClickAwayListener,
  Grid,
} from "@material-ui/core";
import { useFetch } from "../../api/api";
import { AutoInsert, PersonScreen } from "../../common/text.json";
import { makeStyles } from "@material-ui/core/styles";

//data province
function GetDataProvince({
  region,
  searchParamsDistrict,
  setSearchParamsDistrict,
  setRegion,
  setOpenProvince,
  setSearchParamsProvince,
  idInsertProvince,
  openProvince,
  anchorElProvince,
  blank_district,
  blank_ward,
  searchParamsProvince,
  endpoint,
}) {
  const classes = useStyle();
  const [hightLightProvince, setHightLightProvince] = useState();
  const [demoSearchProvince, setDemoSearchProvince] = useState({
    name_like: "",
    type_equal: 1,
  });
  const { data: dataProvince, loading: loadingProvince } = useFetch([
    "get",
    endpoint ? endpoint : "admin/originalRegion",
    demoSearchProvince,
  ]);
  const chooseValueProvince = (value) => {
    let temp = { ...region };
    let tempSearchDistrict = { ...searchParamsDistrict };
    tempSearchDistrict.parent_original_region_id_equal = value.id;
    setSearchParamsDistrict(tempSearchDistrict);
    temp.province = { ...region.province };
    temp.province = value;
    temp.district = blank_district;
    temp.ward = blank_ward;
    setRegion(temp);
    setOpenProvince(false);
    let tempSearchProvince = { ...searchParamsProvince };
    tempSearchProvince.name_like = value.name;
    setSearchParamsProvince(tempSearchProvince);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setDemoSearchProvince(searchParamsProvince);
    }, 200);
    return () => clearTimeout(timer);
  }, [searchParamsProvince]);
  return (
    <Popper
      id={idInsertProvince}
      open={openProvince}
      anchorEl={anchorElProvince}
      placement={"bottom-start"}
      style={{
        backgroundColor: "white",
        zIndex: 10000,
        minWidth: "200px",
      }}
    >
      <Paper className={classes.paperStyle}>
        {dataProvince?.data.map((value, index) => {
          return loadingProvince ? (
            <div className={classes.text} key={index}>
              {AutoInsert.loading}
            </div>
          ) : (
            <div
              className={
                hightLightProvince == value.id
                  ? classes.itemHightLight
                  : classes.itemNotHightLight
              }
              key={index}
              onClick={() => chooseValueProvince(value)}
              onMouseEnter={() => setHightLightProvince(value.id)}
              onMouseLeave={() => setHightLightProvince(0)}
            >
              {value.name}
            </div>
          );
        })}
        {loadingProvince ? (
          <div className={classes.text}>{AutoInsert.loading}</div>
        ) : dataProvince?.data?.length == 0 ? (
          <div className={classes.text} onClick={() => setOpenProvince(false)}>
            {AutoInsert.nodata}
          </div>
        ) : (
          ""
        )}
      </Paper>
    </Popper>
  );
}

//data district
function GetDataDistrict({
  region,
  searchParamsWard,
  setSearchParamsWard,
  setRegion,
  setOpenDistrict,
  searchParamsDistrict,
  setSearchParamsDistrict,
  idInsertDistrict,
  openDistrict,
  anchorElDistrict,
  blank_ward,
  endpoint,
}) {
  const classes = useStyle();
  const [hightLightDistrict, setHightLightDistrict] = useState();
  const [demoSearchDistrict, setDemoSearchDistrict] = useState({
    name_like: "",
    type_equal: 2,
    parent_original_region_id_equal: 0,
  });
  const { data: dataDistrict, loading: loadingDistrict } = useFetch([
    "get",
    endpoint ? endpoint : "admin/originalRegion",
    demoSearchDistrict,
  ]);
  const chooseValueDistrict = (value) => {
    let temp = { ...region };
    let tempSearchWard = { ...searchParamsWard };
    tempSearchWard.parent_original_region_id_equal = value.id;
    setSearchParamsWard(tempSearchWard);
    temp.district = { ...region.district };
    temp.district = value;
    temp.ward = blank_ward;
    setRegion(temp);
    setOpenDistrict(false);
    let tempSearchDistrict = { ...searchParamsDistrict };
    tempSearchDistrict.name_like = value.name;
    setSearchParamsDistrict(tempSearchDistrict);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setDemoSearchDistrict(searchParamsDistrict);
    }, 200);
    return () => clearTimeout(timer);
  }, [searchParamsDistrict]);
  return (
    <Popper
      id={idInsertDistrict}
      open={openDistrict}
      anchorEl={anchorElDistrict}
      placement={"bottom-start"}
      style={{
        backgroundColor: "white",
        zIndex: 10000,
        minWidth: "200px",
      }}
    >
      <Paper className={classes.paperStyle}>
        {dataDistrict?.data.map((value, index) => {
          return loadingDistrict ? (
            <div className={classes.text} key={index}>
              {AutoInsert.loading}
            </div>
          ) : (
            <div
              className={
                hightLightDistrict == value.id
                  ? classes.itemHightLight
                  : classes.itemNotHightLight
              }
              key={index}
              onClick={() => chooseValueDistrict(value)}
              onMouseEnter={() => setHightLightDistrict(value.id)}
              onMouseLeave={() => setHightLightDistrict(0)}
            >
              {value.name}
            </div>
          );
        })}
        {loadingDistrict ? (
          <div className={classes.text}>{AutoInsert.loading}</div>
        ) : dataDistrict?.data?.length == 0 ? (
          <div className={classes.text} onClick={() => setOpenDistrict(false)}>
            {AutoInsert.nodata}
          </div>
        ) : (
          ""
        )}
      </Paper>
    </Popper>
  );
}

//data ward
function GetDataWard({
  region,
  setRegion,
  setOpenWard,
  searchParamsWard,
  setSearchParamsWard,
  idInsertWard,
  openWard,
  anchorElWard,
  endpoint,
}) {
  const classes = useStyle();
  const [hightLightWard, setHightLightWard] = useState();
  const [demoSearchWard, setDemoSearchWard] = useState({
    name_like: "",
    type_equal: 3,
    parent_original_region_id_equal: 0,
  });
  const { data: dataWard, loading: loadingWard } = useFetch([
    "get",
    endpoint ? endpoint : "admin/originalRegion",
    demoSearchWard,
  ]);
  const chooseValueWard = (value) => {
    let temp = { ...region };
    temp.ward = { ...region.ward };
    temp.ward = value;
    setRegion(temp);
    setOpenWard(false);
    let tempSearchWard = { ...searchParamsWard };
    tempSearchWard.name_like = value.name;
    setSearchParamsWard(tempSearchWard);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setDemoSearchWard(searchParamsWard);
    }, 200);
    return () => clearTimeout(timer);
  }, [searchParamsWard]);
  return (
    <Popper
      id={idInsertWard}
      open={openWard}
      anchorEl={anchorElWard}
      placement={"bottom-start"}
      style={{
        backgroundColor: "white",
        zIndex: 10000,
        minWidth: "200px",
      }}
    >
      <Paper className={classes.paperStyle}>
        {dataWard?.data.map((value, index) => {
          return loadingWard ? (
            <div className={classes.text} key={index}>
              {AutoInsert.loading}
            </div>
          ) : (
            <div
              className={
                hightLightWard == value.id
                  ? classes.itemHightLight
                  : classes.itemNotHightLight
              }
              key={index}
              onClick={() => chooseValueWard(value)}
              onMouseEnter={() => setHightLightWard(value.id)}
              onMouseLeave={() => setHightLightWard(0)}
            >
              {value.name}
            </div>
          );
        })}
        {loadingWard ? (
          <div className={classes.text}>{AutoInsert.loading}</div>
        ) : dataWard?.data?.length == 0 ? (
          <div className={classes.text} onClick={() => setOpenWard(false)}>
            {AutoInsert.nodata}
          </div>
        ) : (
          ""
        )}
      </Paper>
    </Popper>
  );
}

//function show
function AutocompleteInsertOriginalRegion({
  setRegion,
  region,
  error,
  endpoint,
}) {
  const [firstLoading, setFirstLoading] = useState(true);
  const blank_district = {
    name: "",
    type: 2,
    parent_original_region_id: 0,
  };
  const blank_ward = {
    name: "",
    type: 3,
    parent_original_region_id: 0,
  };
  const blank_district_search = {
    name_like: "",
    type_equal: 2,
    parent_original_region_id_equal: 0,
  };
  const blank_ward_search = {
    name_like: "",
    type_equal: 3,
    parent_original_region_id_equal: 0,
  };
  const [searchParamsProvince, setSearchParamsProvince] = useState({
    name_like: "",
    type_equal: 1,
  });
  const [searchParamsDistrict, setSearchParamsDistrict] = useState({
    name_like: "",
    type_equal: 2,
    parent_original_region_id_equal: 0,
  });
  const [searchParamsWard, setSearchParamsWard] = useState({
    name_like: "",
    type_equal: 3,
    parent_original_region_id_equal: 0,
  });
  useEffect(() => {
    if (region && firstLoading == true) {
      let tempSearchProvince = { ...searchParamsProvince };
      tempSearchProvince.name_like = region?.province?.name;
      setSearchParamsProvince(tempSearchProvince);
      let tempSearchDistrict = { ...searchParamsDistrict };
      tempSearchDistrict.name_like = region?.district?.name;
      tempSearchDistrict.parent_original_region_id_equal = region?.district
        ?.parent_original_region_id_equal
        ? region?.district?.parent_original_region_id_equal
        : 0;
      setSearchParamsDistrict(tempSearchDistrict);
      let tempSearchWard = { ...searchParamsWard };
      tempSearchWard.name_like = region?.ward?.name;
      tempSearchWard.parent_original_region_id_equal = region?.ward
        ?.parent_original_region_id_equal
        ? region?.ward?.parent_original_region_id_equal
        : 0;
      setSearchParamsWard(tempSearchWard);
      setFirstLoading(false);
    }
  }, [region]);
  const [anchorElProvince, setAnchorElProvince] = useState();
  const [anchorElDistrict, setAnchorElDistrict] = useState();
  const [anchorElWard, setAnchorElWard] = useState();
  const [openProvince, setOpenProvince] = useState(false);
  const [openDistrict, setOpenDistrict] = useState(false);
  const [openWard, setOpenWard] = useState(false);
  const idInsertProvince = openProvince ? "transitions-popper" : undefined;
  const idInsertDistrict = openDistrict ? "transitions-popper" : undefined;
  const idInsertWard = openWard ? "transitions-popper" : undefined;
  const changeValueProvince = (e) => {
    let temp = { ...region };
    temp.province = { ...region.province };
    temp.province.name = e.target.value;
    temp.province.id = 0;
    temp.district = blank_district;
    temp.ward = blank_ward;
    setRegion(temp);
    setOpenProvince(true);
    setAnchorElProvince(e.currentTarget);
    let searchProvince = { ...searchParamsProvince };
    searchProvince.name_like = e.target.value;
    setSearchParamsProvince(searchProvince);
    setSearchParamsDistrict(blank_district_search);
    setSearchParamsWard(blank_ward_search);
  };
  const changeValueDistrict = (e) => {
    let temp = { ...region };
    temp.district = { ...region.district };
    temp.district.name = e.target.value;
    temp.district.id = 0;
    temp.district.parent_original_region_id = region?.province?.id
      ? region?.province?.id
      : 0;
    temp.ward = blank_ward;
    setRegion(temp);
    setOpenDistrict(true);
    setAnchorElDistrict(e.currentTarget);
    let searchDistrict = { ...searchParamsDistrict };
    searchDistrict.name_like = e.target.value;
    searchDistrict.parent_original_region_id_equal = region?.province?.id
      ? region?.province?.id
      : 0;
    setSearchParamsDistrict(searchDistrict);
    setSearchParamsWard(blank_ward_search);
  };
  const changeValueWard = (e) => {
    let temp = { ...region };
    temp.ward = { ...region.ward };
    temp.ward.name = e.target.value;
    temp.ward.id = 0;
    temp.ward.parent_original_region_id = region?.district?.id
      ? region?.district?.id
      : 0;
    setRegion(temp);
    setOpenWard(true);
    setAnchorElWard(e.currentTarget);
    let searchWard = { ...searchParamsWard };
    searchWard.name_like = e.target.value;
    searchWard.parent_original_region_id_equal = region?.district?.id
      ? region?.district?.id
      : 0;
    setSearchParamsWard(searchWard);
  };
  const handleClickAway = () => {
    setOpenProvince(false);
    setOpenDistrict(false);
    setOpenWard(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            fullWidth={true}
            aria-describedby={idInsertProvince}
            variant={"outlined"}
            size={"small"}
            value={region.province?.name || ""}
            onChange={(e) => changeValueProvince(e)}
            label={PersonScreen.province}
            error={error}
            helperText={error}
            onClick={(e) => {
              setOpenProvince(true);
              setAnchorElProvince(e.currentTarget);
              setOpenWard(false);
              setOpenDistrict(false);
            }}
          />
          {openProvince == true && (
            <GetDataProvince
              region={region}
              searchParamsDistrict={searchParamsDistrict}
              setSearchParamsDistrict={setSearchParamsDistrict}
              setRegion={setRegion}
              setOpenProvince={setOpenProvince}
              setSearchParamsProvince={setSearchParamsProvince}
              idInsertProvince={idInsertProvince}
              openProvince={openProvince}
              anchorElProvince={anchorElProvince}
              blank_district={blank_district}
              blank_ward={blank_ward}
              searchParamsProvince={searchParamsProvince}
              endpoint={endpoint}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth={true}
            aria-describedby={idInsertDistrict}
            variant={"outlined"}
            size={"small"}
            value={region.district?.name || ""}
            onChange={(e) => changeValueDistrict(e)}
            label={PersonScreen.district}
            error={error}
            helperText={error}
            onClick={(e) => {
              setOpenDistrict(true);
              setAnchorElDistrict(e.currentTarget);
              setOpenProvince(false);
              setOpenWard(false);
            }}
          />
          {openDistrict == true && (
            <GetDataDistrict
              region={region}
              searchParamsWard={searchParamsWard}
              setSearchParamsWard={setSearchParamsWard}
              setRegion={setRegion}
              setOpenDistrict={setOpenDistrict}
              searchParamsDistrict={searchParamsDistrict}
              setSearchParamsDistrict={setSearchParamsDistrict}
              idInsertDistrict={idInsertDistrict}
              openDistrict={openDistrict}
              anchorElDistrict={anchorElDistrict}
              blank_ward={blank_ward}
              endpoint={endpoint}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth={true}
            aria-describedby={idInsertWard}
            variant={"outlined"}
            size={"small"}
            value={region.ward?.name || ""}
            onChange={(e) => changeValueWard(e)}
            label={PersonScreen.ward}
            error={error}
            helperText={error}
            onClick={(e) => {
              setOpenWard(true);
              setAnchorElWard(e.currentTarget);
              setOpenProvince(false);
              setOpenDistrict(false);
            }}
          />
          {openWard == true && (
            <GetDataWard
              region={region}
              setRegion={setRegion}
              setOpenWard={setOpenWard}
              searchParamsWard={searchParamsWard}
              setSearchParamsWard={setSearchParamsWard}
              idInsertWard={idInsertWard}
              openWard={openWard}
              anchorElWard={anchorElWard}
              endpoint={endpoint}
            />
          )}
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
export default AutocompleteInsertOriginalRegion;

const useStyle = makeStyles((theme) => ({
  itemNotHightLight: {
    padding: 10,
    borderBottom: "#f3f3f3 solid 1px",
    minHeight: 30,
    wordBreak: "keep-all",
  },
  itemHightLight: {
    padding: 10,
    borderBottom: "#f3f3f3 solid 1px",
    minHeight: 30,
    wordBreak: "keep-all",
    backgroundColor: "#c2e9fb",
  },
  text: {
    padding: 5,
    minHeight: 30,
    backgroundColor: "white",
  },
  paperStyle: {
    maxHeight: "200px",
    overflow: "auto",
    maxWidth: 500,
    backgroundColor: "white",
    minWidth: "100px",
  },
}));
