import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  makeStyles,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  Slide,
  Grid,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import { useDialog } from "../Dialog";
import { RouteText, PersonScreen } from "../../common/text.json";
import Search from "../../containers/Admin/Devotee/DevoteeRelative/Search";
import { useParams } from "react-router";
import { AuthContext } from "../../containers/AuthProvider";
import { gender, role_id } from "../../common/constants";

export default function ButtonLink(props) {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const { handleSelect, value, relativeId } = props;
  const { admin } = useContext(AuthContext);

  const { dialog, handleClose } = useDialog();
  const history = useHistory();
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const searchDevotee = async () => {
    await dialog({
      title: RouteText.search,
      content: (
        <Search
          className={classes.dialog}
          close={handleClose}
          handleSelect={handleSelect}
          relativeId={relativeId}
        />
      ),
      dialogProps: {
        maxWidth: "100%",
      },
    });
  };
  const params = useParams();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.name}:</p>
              <p className={classes.textBody}>{value?.name}</p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.sex}:</p>
              <p className={classes.textBody}>
                {value?.sex == gender.Nam ? "Nam" : "Nữ"}
              </p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.dob_date}:</p>
              <p className={classes.textBody}>
                {value?.dob_date}
                {" / "}
                {value?.dob_month}
                {" / "}
                {value?.dob_year}
              </p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.id_number}:</p>
              <p className={classes.textBody}>{value?.id_number}</p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>
                {PersonScreen.id_number_2020}:
              </p>
              <p className={classes.textBody}>{value?.id_number_2020}</p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.phone_number}:</p>
              <p className={classes.textBody}>{value?.phone_number}</p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.email}:</p>
              <p className={classes.textBody}>{value?.email}</p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.ethnic}:</p>
              <p className={classes.textBody}>{value?.ethnic_id?.name}</p>
            </Grid>
            <Grid item xs={6} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.religion}:</p>
              <p className={classes.textBody}>{value?.religion?.name}</p>
            </Grid>
            <Grid item xs={12} className={classes.displayText}>
              <p className={classes.textHeader}>{PersonScreen.address}:</p>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.textBody}>
                {value?.ward_id?.name}
                {" - "}
                {value?.district_id?.name}
                {" - "}
                {value?.province_id?.name}
              </p>
              <p className={classes.textBody}>{value?.address}</p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => history.push(`/devotee-information/${value?.id}`)}
          >
            Thông tin chi tiết {">>>>"}
          </Button>
        </DialogActions>
      </Dialog>
      {!params.idFile && (
        <IconButton
          color="primary"
          onClick={searchDevotee}
          style={{ height: "40px", width: "40px", marginLeft: "10px" }}
        >
          <SearchIcon />
        </IconButton>
      )}
      {!value?.id && (
        <p style={{ fontSize: "14px", color: "#f44336" }}>("Chọn NCC")</p>
      )}
      {value?.province_id?.id == admin.region ||
      value?.district_id?.id == admin.region ||
      value?.ward_id?.id == admin.region ||
      value?.province_id == admin.region ||
      value?.district_id == admin.region ||
      value?.ward_id == admin.region ||
      admin.role_id < role_id.DEPARTMENT_PROVINCE ? (
        <IconButton
          color="primary"
          onClickCapture={() =>
            window.open(
              process.env.REACT_APP_URL + `/devotee-information/${value?.id}`,
              "_blank"
            )
          }
          style={{ height: "40px", width: "40px", marginLeft: "10px" }}
        >
          <VisibilityIcon />
        </IconButton>
      ) : (
        ""
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    textDecoration: "none",
    color: "#1166e4",
  },
  textHeader: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    margin: 0,
  },
  displayText: {
    display: "flex",
  },
  textBody: {
    fontSize: "1.3rem",
    paddingLeft: 10,
    margin: 0,
    color: "blue",
  },
});
