import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAPI } from "../../../api/api";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import PhoneIcon from "@material-ui/icons/Phone";
import { firebase, auth } from "../firebase";
import Dialog from "@material-ui/core/Dialog";
import { AuthContext } from "../../AuthProvider";
import { send_otp, ErrorText, LoginScreen } from "../../../common/text.json";
import OtpInput from "react-otp-input";
import { peoplePrefix } from "../../../routes/PeopleRoutes";
import { GoogleLogin } from "react-google-login";
import { adminPrefix } from "../../../routes/AdminRoutes";
import { toast } from "react-toastify";
import google from "../../../assets/image/google.png";

export default function PeopleLogin(props) {
  const history = useHistory();
  const { admin, user } = useContext(AuthContext);
  useEffect(() => {
    if (admin) {
      history.push(adminPrefix);
    }
    if (user) {
      history.push(peoplePrefix);
    }
  });
  const [phoneNumber, setPhoneNumber] = useState();
  return <Login phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />;
}
const Login = ({ phoneNumber, setPhoneNumber }) => {
  const classes = useStyle();
  const api = useAPI();
  const history = useHistory();
  const { updateToken, updateUser } = useContext(AuthContext);

  const [code, setCode] = useState("");
  const [dialog, setDialog] = useState(false);
  const [confirmCode, setConfirmCode] = useState();
  const [error, setError] = useState();
  const [validateNumber, setValidateNumber] = useState(false);
  const signIn = async () => {
    if (phoneNumber) {
      setValidateNumber(false);
      let verify = (window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            signIn();
          },
        }
      ));
      auth
        .signInWithPhoneNumber("+84" + phoneNumber, verify)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setConfirmCode(confirmationResult);
          setDialog(true);
        })
        .catch((err) => {
          return err;
        });
    } else {
      setValidateNumber(true);
    }
  };

  const verify = () => {
    if (code) {
      confirmCode
        .confirm(code)
        .then(async (result) => {
          try {
            const res = await api.fetcher("post", "/people/login", {
              phone_number: phoneNumber,
            });
            if (res) {
              await updateToken(res.token);
              updateUser(res.user);
              if (!res.user.name) {
                history.push(peoplePrefix + "/update-info");
              } else {
                history.push(peoplePrefix);
              }
            }
          } catch (error) {}
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const send_again = () => {
    window.location.reload();
    setDialog(false);
  };

  const handleClose = () => {
    setDialog(false);
  };
  const checkEnterPress = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      signIn();
    }
  };

  const loginGoogle = async (data) => {
    try {
      const res = await api.fetcher("post", "people/login-google", {
        email: data.email,
      });
      if (res) {
        await updateToken(res.token);
        await updateUser(res.user);
        history.push("/");
      }
    } catch (e) {
      if (e?.data?.error) {
        toast.error(e?.data?.error, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  const responseGoogle = async (response) => {
    var res = response.profileObj;
    loginGoogle(res);
  };
  return (
    <form style={{ padding: 10 }}>
      <Grid className={classes.input}>
        <Input
          autoFocus={true}
          className="input1"
          type="number"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setValidateNumber(false);
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 9);
          }}
          id="input-with-icon-adornment"
          placeholder={send_otp.type_sdt}
          startAdornment={
            <InputAdornment position="start">
              <PhoneIcon style={{ fontSize: 25 }} color="action" />
              +84
            </InputAdornment>
          }
          onKeyDown={(e) => {
            checkEnterPress(e);
          }}
          onKeyPress={(e) => {
            checkEnterPress(e);
          }}
        />
        {!validateNumber ? null : (
          <FormHelperText
            style={{ marginTop: 5, marginLeft: 30 }}
            error={validateNumber}
          >
            {send_otp.sdt + " " + ErrorText.required}
          </FormHelperText>
        )}
      </Grid>
      <Grid className={classes.button}>
        <Button
          id="sign-in-button"
          onClick={() => signIn()}
          color="primary"
          variant="contained"
          style={{
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
          }}
        >
          {send_otp.verify}
        </Button>
        <GoogleLogin
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              variant="contained"
              style={{
                width: "100%",
                borderRadius: 10,
                backgroundColor: "#dd4b39",
                color: "white",
                padding: "0px 16px",
              }}
            >
              <img
                alt=""
                src={google}
                width={35}
                height={35}
                style={{ marginRight: 10 }}
              />
              {LoginScreen.google_login}
            </Button>
          )}
          clientId={
            process.env.GOOGLE_CLIENT_ID
              ? process.env.GOOGLE_CLIENT_ID
              : "30835151183-iofkjm8sel9e3vkknn57l2uh5lb6pp7m.apps.googleusercontent.com"
          }
          onSuccess={(res) => responseGoogle(res)}
          onFailure={responseGoogle}
        />
      </Grid>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className={classes.input}
          style={{ width: 350, padding: "30px 20px" }}
        >
          <h1
            style={{
              fontWeight: 600,
              fontSize: 20,
              color: "#000000c9",
            }}
          >
            {send_otp.verify_sdt}
          </h1>
          <span style={{ fontSize: 16, marginBottom: 20 }}>
            {send_otp.type_otp_sent + " +84" + phoneNumber}
          </span>
          <OtpInput
            value={code}
            onChange={(e) => setCode(e)}
            numInputs={6}
            separator={<span style={{ width: 13 }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid #CFD3DB",
              borderRadius: 4,
              width: 40,
              height: 40,
              fontSize: 20,
              color: "#000",
              caretColor: "#396486",
            }}
            focusStyle={{
              border: "1px solid #0000006e",
              outline: "none",
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                e.preventDefault();
                verify();
              }
            }}
            onKeyPress={(e) => {
              if (e.keyCode == 13) {
                e.preventDefault();
                verify();
              }
            }}
          />
          {code ? null : (
            <FormHelperText
              style={{ marginTop: 5, marginLeft: 5 }}
              error={true}
            >
              {send_otp.otp + " " + ErrorText.required}
            </FormHelperText>
          )}
          {error != undefined ? (
            <FormHelperText
              style={{ marginTop: 5, marginLeft: 5 }}
              error={true}
            >
              {send_otp.otp_invalidate}
            </FormHelperText>
          ) : null}
          <Button
            id="sign-in-button"
            onClick={verify}
            color="primary"
            variant="contained"
            style={{
              marginTop: 15,
              marginBottom: 10,
              width: "100%",
              borderRadius: 10,
            }}
          >
            {send_otp.login}
          </Button>
          {error == undefined ? null : (
            <Button
              id="sign-in-button"
              onClick={send_again}
              color="primary"
              variant="contained"
              style={{
                marginTop: 15,
                marginBottom: 10,
                width: "100%",
                borderRadius: 10,
              }}
            >
              {send_otp.send_again}
            </Button>
          )}
        </div>
      </Dialog>
    </form>
  );
};

const useStyle = makeStyles((theme) => ({
  input: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 20,
  },
  otp_input: {
    "&:focus": {
      border: "1px solid red",
    },
  },
  container: {
    width: "100%",
    height: "100%",
    background: "#efebe9",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    minHeight: "585px",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `linear-gradient(to right, #e1f5fe, #0091ea)`,
    // backgroundImage: `url(${bgLogin})`,
    alignContent: "center",
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 270px)",
    },
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 200px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 200px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
}));
