import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { maxColumnWidth } from "./constants";

const useCheckedColumns = ({ columns, filterFields }) => {
  const [columnChecked, setColumnChecked] = useState([]);
  const [filterFieldChecked, setFilterFieldChecked] = useState([]);
  const _columnChecked = columnChecked.map((pre, index) => ({
    ...pre,
    title: columns[index]?.title,
  }));
  const _filterFieldChecked = filterFieldChecked.map((f, index) => ({
    ...f,
  }));
  const location = useLocation();
  const [newCache, setNewCache] = useState();
  function loadCache() {
    if (localStorage.getItem([location.pathname]) != "undefined" && columns) {
      const cache = JSON.parse(localStorage.getItem([location.pathname]));
      if (cache?.columnChecked && columns) {
        setColumnChecked(
          columns.map((e, index) => ({
            ...e,
            display: cache?.columnChecked[index]
              ? cache?.columnChecked[index]?.display
              : true,
            width: cache?.columnChecked[index]
              ? cache?.columnChecked[index]?.width
              : null,
          }))
        );
      } else {
        setColumnChecked(columns);
      }
      if (cache?.filterFieldChecked && filterFields) {
        setFilterFieldChecked(
          filterFields.map((e, index) => ({
            ...e,
            display: cache?.filterFieldChecked[index]
              ? cache?.filterFieldChecked[index]?.display
              : true,
          }))
        );
      } else {
        setFilterFieldChecked(filterFields);
      }
    }
  }

  useEffect(() => {
    loadCache();
  }, []);

  useEffect(() => {
    setNewCache(
      JSON.stringify({
        columnChecked: Object.assign(
          columnChecked.map((e) => ({
            field: e.field,
            display: e.display,
            width: e?.width ? e.width : null,
          }))
        ),
        filterFieldChecked: Object.assign(
          filterFieldChecked.map((e) => ({
            field: e.field,
            display: e.display,
          }))
        ),
      })
    );
  }, [columnChecked, filterFieldChecked]);

  useEffect(() => {
    localStorage.setItem([location.pathname], newCache);
  }, [newCache]);

  const handleChangeColumns = (column, index) => {
    setColumnChecked((pre) =>
      pre.map((e) =>
        e.field == column.field ? { ...e, display: !e.display } : e
      )
    );
  };

  const handleChangeFilterFields = (field, index) => {
    setFilterFieldChecked((pre) =>
      pre.map((e) =>
        e.field == field.field ? { ...e, display: !e.display } : e
      )
    );
  };

  const defaultColumns = () => {
    setColumnChecked(columns);
  };

  const defaultFilter = () => {
    setFilterFieldChecked(filterFields);
  };

  const handleWidthChangeColumns = (columns) => {
    setColumnChecked((pre) =>
      pre.map((e) => {
        const column = columns.find((col) => col[0] == e.field);
        if (column) {
          return {
            ...e,
            width: column[1] >= maxColumnWidth ? maxColumnWidth : column[1],
          };
        } else {
          return e;
        }
      })
    );
  };
  return {
    columnChecked: _columnChecked,
    filterFieldChecked: _filterFieldChecked,
    handleChangeColumns,
    handleChangeFilterFields,
    defaultColumns,
    defaultFilter,
    handleWidthChangeColumns,
  };
};

export default useCheckedColumns;
