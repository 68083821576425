import { lazy } from "react";

const PeopleLogin = lazy(() => import("../containers/Auth/People/Login"));

const DashBroad = lazy(() =>
  import("../containers/People/Dashbroad/Dashbroad")
);
const PeopleDevoteeFile = lazy(() =>
  import("../containers/People/DevoteeFile/List")
);
const PeopleDevoteeFileForm = lazy(() =>
  import("../containers/People/DevoteeFile/Form")
);
const PeopleRelativeFile = lazy(() =>
  import("../containers/People/RelativeFile/List")
);
const PeopleRelativeFileForm = lazy(() =>
  import("../containers/People/RelativeFile/Form")
);

const UpdateInfo = lazy(() => import("../containers/Auth/People/UpdateInfo"));

export const peoplePrefix = "/people";
export const peopleApi = "/people";

export function PeopleUnAuthLoginRoute() {
  return [
    {
      path: "/login",
      component: PeopleLogin,
      perm: "",
    },
  ];
}

export function PeopleRoutes() {
  const peopleRoutes = [
    {
      path: "/",
      component: DashBroad,
      name: "Trang chủ",
    },
    {
      path: "/update-info",
      component: UpdateInfo,
      name: "Cập nhật thông tin",
    },
    {
      path: "/devoteePeopleFile",
      component: PeopleDevoteeFile,
      protected: true,
      name: "Hồ sơ người có công",
    },
    {
      path: "/devoteePeopleFile/create",
      component: PeopleDevoteeFileForm,
      protected: true,
      name: "Tạo hồ sơ người có công",
    },
    {
      path: "/devoteePeopleFile/:id",
      component: PeopleDevoteeFileForm,
      protected: true,
      name: "Thông tin hồ sơ người có công",
    },
    {
      path: "/relativePeopleFile",
      component: PeopleRelativeFile,
      protected: true,
      name: "Hồ sơ thân nhân người có công",
    },
    {
      path: "/relativePeopleFile/create",
      component: PeopleRelativeFileForm,
      protected: true,
      name: "Tạo mới hồ sơ thân nhân người có công",
    },
    {
      path: "/relativePeopleFile/:id",
      component: PeopleRelativeFileForm,
      protected: true,
      name: "Thông tin thân nhân người có công",
    },
  ];

  return { peopleRoutes };
}
