import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import TextType from "./TextType";
import NumberType from "./NumberType";
import DateType from "./DateType";
import ListType from "./ListType";
import CategoryType from "./CategoryType";

export default function FilterAttribute({
  fileTypeId,
  attribute,
  setAttribute,
  reload,
  setReload,
  data,
}) {
  const typeData = {
    text: 1,
    number: 2,
    date: 3,
    list: 4,
    category: 5,
  };
  const changeValue = (value, index) => {
    let temp = [...attribute];
    temp[index].value = value;
    setAttribute(temp);
  };
  const changeObject = (value, index) => {
    let temp = [...attribute];
    temp[index].value_object = value;
    temp[index].value = value?.name;
    setAttribute(temp);
  };

  const changeRangerType = (value, index, field) => {
    if (field == "start") {
      let temp = [...attribute];
      temp[index].start = value;
      setAttribute(temp);
    } else if (field == "end") {
      let temp = [...attribute];
      temp[index].end = value;
      setAttribute(temp);
    }
  };

  return (
    <Fragment>
      {data?.length > 0 && attribute.some((value) => value.display == true) && (
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
            Thông tin bổ sung
          </span>
        </div>
      )}
      <Grid container spacing={3}>
        {attribute.map((value, index) => {
          return (
            value.display == true && (
              <Grid
                item
                key={index}
                xs={
                  value?.value_type == typeData.number ||
                  value?.value_type == typeData.date
                    ? 12
                    : 6
                }
              >
                {value?.value_type == typeData.text && (
                  <TextType
                    value={value}
                    changeValue={(e) => changeValue(e, index)}
                    reload={reload}
                    setReload={setReload}
                  />
                )}
                {value?.value_type == typeData.number && (
                  <NumberType
                    value={value}
                    changeValue={(e) => changeValue(e, index)}
                    reload={reload}
                    setReload={setReload}
                    changeNumberType={changeRangerType}
                    index={index}
                  />
                )}
                {value?.value_type == typeData.date && (
                  <DateType
                    value={value}
                    changeValue={(e) => changeValue(e, index)}
                    reload={reload}
                    setReload={setReload}
                    changeDateType={changeRangerType}
                    index={index}
                  />
                )}
                {value?.value_type == typeData.list && (
                  <ListType
                    value={value}
                    changeValue={(e) => changeValue(e, index)}
                    reload={reload}
                    setReload={setReload}
                  />
                )}
                {value?.value_type == typeData.category && (
                  <CategoryType
                    value={value}
                    changeValue={(e) => changeObject(e, index)}
                    reload={reload}
                    setReload={setReload}
                  />
                )}
              </Grid>
            )
          );
        })}
      </Grid>
    </Fragment>
  );
}
