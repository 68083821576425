import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFetch } from "../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chip, Popper } from "@material-ui/core";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default React.memo((props) => {
  const { value, handleChange, label, params, ...otherProps } = props;
  const [query, setQuery] = useState();
  const location = useLocation();
  const [firstCheck, setFirstCheck] = useState(false);
  const endpoint =
    "/admin/devoteeType?select_value=" +
    JSON.stringify(["id", "name", "code"]) +
    "&relation=" +
    JSON.stringify([]);
  const queryField = "name_like";
  const labelField = "name";
  const [array, setArray] = useState([]);
  const { data, loading } = useFetch(
    endpoint && ["get", endpoint, JSON.stringify({ ...query, ...params })]
  );
  const options = data?.data || [];

  const handleAddArray = (value) => {
    const check = array.some((data) => data?.id == value?.id);
    if (check == false) {
      let temp = [...array];
      temp.push(value);
      setArray(temp);
    }
  };

  useEffect(() => {
    if (location?.search && firstCheck == false && options.length > 0) {
      let data_params = location.search.split("=");
      let devotee_type = data_params[1];
      const check = array.some((dataCheck) => dataCheck?.id == devotee_type);
      if (check == false) {
        let temp = [...array];
        let elementAdd = options.find((element) => element?.id == devotee_type);
        if (elementAdd?.code) {
          temp.push(elementAdd);
          setArray(temp);
        }
      }
      setFirstCheck(true);
    }
  }, [location, firstCheck, options, array]);
  useEffect(() => {
    if (value == undefined && location?.search == "") {
      setArray([]);
    }
  }, [value]);
  useEffect(() => {
    if (array.length > 0) {
      let arrayId = [];
      array.forEach((value) => {
        arrayId.push(value.id);
      });
      handleChange(arrayId);
    } else {
      handleChange(null);
    }
  }, [array]);
  // useEffect(() => {
  //   if (!value) {
  //     setArray([]);
  //   }
  // }, [value]);

  const deleteDevoteeType = (index) => {
    let temp = [...array];
    temp.splice(index, 1);
    setArray(temp);
  };
  const empty = {};
  return (
    <>
      <Autocomplete
        size={"small"}
        includeInputInList={true}
        openOnFocus={true}
        loading={loading}
        loadingText={"Đang tải"}
        noOptionsText={"Không có dữ liệu"}
        PopperComponent={(props) => (
          <Popper {...props} style={{ top: "-10px" }}>
            {props.children}
          </Popper>
        )}
        value={empty}
        options={options}
        getOptionSelected={(o, v) => o?.id == v?.id}
        getOptionLabel={(option) => option?.[labelField] || ""}
        renderOption={(option, state) => {
          return <div style={{ padding: 5 }}>{option?.[labelField]}</div>;
        }}
        onChange={(event, value, reason) => {
          if (!value) {
          } else {
            handleAddArray(value);
          }
        }}
        onClose={() => {
          setQuery();
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={otherProps?.variant}
            label={label}
            onChange={(event) => setQuery({ [queryField]: event.target.value })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <div>
        {array.map((dataDevotee, index) => {
          return (
            <Chip
              label={dataDevotee?.code}
              onDelete={() => deleteDevoteeType(index)}
              style={{ margin: "10px 10px 0px 0px" }}
              key={index}
              color="primary"
              variant="outlined"
            />
          );
        })}
      </div>
    </>
  );
});
