import { IconButton } from "@material-ui/core";
import React from "react";
import { useAPI } from "../../api/api";
import { useDialog } from "../Dialog";
import DeleteIcon from "@material-ui/icons/Delete";

const Delete = React.memo((props) => {
  const { endpoint, value, action, message, handleConfirm } = props;
  const api = useAPI();
  const { dialog, handleClose } = useDialog();

  const deleteConfirm = async (endpoint, action, message) => {
    await dialog({
      title: message ? message : "Xác nhận xóa bản ghi này",
      type: "confirm",
      confirmationText: "Đồng ý",
      cancellationText: "Hủy bỏ",
    });
    if (value?.id) {
      try {
        const res = await api.fetcher("delete", endpoint, {
          action: action,
        });
        if (res) {
          handleConfirm(true);
          handleClose();
        }
      } catch (e) {
        handleClose();
      }
    }
    handleConfirm(true);
    handleClose();
  };
  return (
    <div>
      <IconButton
        title={"Xóa"}
        style={{
          color: "#c90303c7",
          padding: 3,
        }}
        onClick={() => deleteConfirm(endpoint, action, message)}
      >
        {value ? <DeleteIcon /> : <DeleteIcon />}
      </IconButton>
    </div>
  );
});
export default Delete;
