import React from "react";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { SvgIcon } from "@material-ui/core";

export default function ButtonAdd(props) {
  const { text, onClick, disabled, ...otherProps } = props;
  return (
    <div
      style={{
        border: " 1px solid #036FE3",
        height: "36px",
        minWidth: "120px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: "#036FE3",
        fontSize: 16,
      }}
      onClick={() => onClick()}
      {...otherProps}
    >
      <SvgIcon style={{ marginTop: 6 }} viewBox="0 0 22 22" component={Add} />
      {text && "Thêm " + text}
    </div>
  );
}
