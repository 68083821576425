import React, { useState, useEffect, createContext } from "react";
// import Cookies from 'js-cookie';
import { useAPI } from "../api/api";
import Loading from "../components/Loading";
import { peoplePrefix } from "../routes/PeopleRoutes";
//-------------------------------------
export const AuthContext = createContext(null);

/**
 * Restore auth user from access_token is persisted in localStorage.
 *
 * TODO: handle refresh token in here.
 */
const AuthProvider = React.memo((props) => {
  const [user, setUser] = useState(null);
  const [perms, setPerms] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [document, setDocument] = useState(null);
  const [document_data, setDocumentData] = useState(null);
  const api = useAPI();
  const updateToken = async (token, remember) => {
    if (remember) {
      localStorage.setItem("user_token", token);
    } else {
      // Cookies.set('user_token', token);
      localStorage.setItem("user_token", token);
    }
  };

  const updateAdminToken = async (token, remember) => {
    if (remember) {
      localStorage.setItem("admin_token", token);
    } else {
      // Cookies.set('admin_token', token);
      localStorage.setItem("admin_token", token);
    }
  };

  const updateUser = async (data) => {
    setUser(data);
  };

  const updateAdmin = async (data) => {
    setAdmin(data);
  };

  const updatePermission = async (data) => {
    setPerms(data);
  };

  const updateDocumentHelpFile = async () => {
    const document_data = await api.fetcher("get", "/documentHelpFile");
    localStorage.setItem('document_data', JSON.stringify(document_data))
    setDocument(document_data);
    setDocumentData(document_data);
  };

  const clear = async (role) => {
    if (admin) {
      localStorage.removeItem("admin_token");
      // Cookies.remove('admin_token');
      setAdmin(null);
    } else {
      localStorage.removeItem("user_token");
      // Cookies.remove('user_token');
      setUser(null);
    }
  };

  const revalidate = async () => {
    getUserInfo();
  };

  const value = {
    user,
    admin,
    perms,
    updatePermission,
    updateToken,
    updateAdminToken,
    updateAdmin,
    updateUser,
    clear,
    revalidate,
    document,
    document_data,
    updateDocumentHelpFile,
  };

  const getUserInfo = async () => {
    const token = localStorage.getItem("admin_token")
      ? localStorage.getItem("admin_token")
      : localStorage.getItem("user_token");
    const userToken = localStorage.getItem("user_token");
    if (token) {
      try {
        const document_data_cache = localStorage.getItem('document_data')
        if(document_data_cache) {
          const document_data = JSON.parse(document_data_cache);
          setDocument(document_data);
          setDocumentData(document_data);
        } else {
          const document_data = await api.fetcher("get", "/documentHelpFile");
          localStorage.setItem('document_data', JSON.stringify(document_data))
          setDocument(document_data);
          setDocumentData(document_data);
        }
        if (userToken) {
          const res = await api.fetcher("get", peoplePrefix + "/me");
          setUser(res);
          setAdmin(null);
        } else {
          const now = new Date().getTime() / 1000
          const last_time = localStorage.getItem('last_time');
          const infoMe = localStorage.getItem('infoMe')
          const infoRole = localStorage.getItem('infoRole')
          if(!last_time || !infoMe || !infoRole) {
            localStorage.setItem('last_time', now)
            const res = await api.fetcher("get", "/me");
            const permission_group = await api.fetcher(
              "get",
              "unauth/role/" + res?.role_id
            );
            setPerms(
              convertDataPerm(permission_group?.permissions, res?.permissions)
            );
            setAdmin(res);
            setUser(null);
            localStorage.setItem('infoMe', JSON.stringify(res))
            localStorage.setItem('infoRole', JSON.stringify(permission_group))
          } else {
            if((parseFloat(last_time) + 1800) < now) {
              const res = await api.fetcher("get", "/me");
              const permission_group = await api.fetcher(
                "get",
                "unauth/role/" + res?.role_id
              );
              setPerms(
                convertDataPerm(permission_group?.permissions, res?.permissions)
              );
              setAdmin(res);
              setUser(null);
              localStorage.setItem('infoMe', JSON.stringify(res))
              localStorage.setItem('infoRole', JSON.stringify(permission_group))
              localStorage.setItem('last_time', now)
            } else {
              let permissionGroup = JSON.parse(infoRole)
              let resData = JSON.parse(infoMe)
              setPerms(
                convertDataPerm(permissionGroup?.permissions, resData?.permissions)
              );
              setAdmin(resData);
              setUser(null);
            }
          }
        }
      } catch (error) {
        setUser(null);
        setAdmin(null);
        localStorage.removeItem("admin_token");
        localStorage.removeItem("user_token");
      }
    } else {
      setUser(null);
      setAdmin(null);
    }
    setLoaded(true);
  };

  const convertDataPerm = (user_perm, group_perm) => {
    let array = [];
    group_perm.forEach((data_group_perm) => {
      user_perm.forEach((data_user_perm) => {
        if (data_group_perm?.id == data_user_perm?.id) {
          array.push(data_user_perm);
        }
      });
    });
    return array;
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <AuthContext.Provider value={value} {...props} />;
});

export default AuthProvider;
