import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Tabs, Tab } from "@material-ui/core";
import { AuthContext } from "../AuthProvider";
import { useHistory, useLocation } from "react-router-dom";
import { adminPrefix } from "../../routes/AdminRoutes";
import "./Login.css";
import logo from "../../../src/assets/image/logo.png";
import FormResetPassword from "./FormResetPassword";
import { FormForgotPassword } from "./FormForgotPassword";
import { FormLogin } from "./FormLogin";
import SendOTP from "./SendOTP";
import { useFetch } from "../../api/api";
import { peoplePrefix } from "../../routes/PeopleRoutes";
import PeopleLogin from "./People/Login";
import LoginServe3 from "./People/LoginServe3";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    background: "#efebe9",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    minHeight: "585px",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `linear-gradient(to right, #e1f5fe, #0091ea)`,
    // backgroundImage: `url(${bgLogin})`,
    alignContent: "center",
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 270px)",
    },
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 200px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 200px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  contentRight: {
    padding: 10,
    backgroundColor: "#e3f2fd50",
    borderRadius: 14,
    width: 400,
    maxHeight: 700,
    position: "absolute",
    display: "flex",
    flexFlow: "column",
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      borderRadius: 0,
      justifyContent: "center",
    },
    boxShadow: "20px 20px 30px #396486",
  },
  tab: {
    width: "50%",
    fontWeight: "bold",
    background: "#ffffffc7",
  },
  tabActive: {
    background: "#0a49ed91",
    "-webkit-transition": "background-color 1000ms linear",
    "-moz-transition": "background-color 1000ms linear",
    "-o-transition": "background-color 1000ms linear",
    "-ms-transition": "background-color 1000ms linear",
    transition: "background-color 1000ms linear",
    color: "white",
  },
}));

export default function Login(props) {
  const classes = useStyle();
  const location = useLocation();

  const { admin, user } = useContext(AuthContext);
  const history = useHistory();
  useEffect(() => {
    if (admin) {
      history.push(adminPrefix);
    }
    if (user) {
      history.push(peoplePrefix);
    }
    if (!location?.pathname?.includes("/resetPassword")) {
      history.push("/");
    }
  }, []);
  const { data: config } = useFetch(["get", "/infoSetting"]);
  const [screen, setScreen] = useState("people");

  return (
    <div
      style={{
        padding: 0,
        maxWidth: "100vw",
        height: "100%",
        background: "#efebe9",
      }}
    >
      <div style={{ height: "100%", background: "#efebe9" }}>
        <div className={classes.container}>
          <Card
            className={classes.content}
            style={
              config?.background_login_type == 2
                ? {
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_UPLOAD_IMAGE_URL +
                      "/storage/" +
                      config?.background_login_image +
                      ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto 100%",
                    backgroundPosition: "center",
                  }
                : {
                    backgroundColor: config?.background_login_color
                      ? config?.background_login_color
                      : "#4381b2",
                  }
            }
          >
            <div className={classes.contentRight}>
              <div
                style={{
                  marginBottom: 15,
                  textAlign: "center",
                }}
              >
                <img style={{ maxWidth: 317, height: 200 }} src={logo} alt="" />
              </div>
              <div>
                {location?.pathname == "/sendOTP" && <SendOTP />}
                {location?.pathname == "/forgotPassword" && (
                  <FormForgotPassword />
                )}
                {location?.pathname != "/forgotPassword" &&
                  location?.pathname != "/sendOTP" &&
                  !location?.pathname?.includes("/resetPassword") && (
                    <FormLogin />
                  )}
                {location?.pathname?.includes("/resetPassword") && (
                  <FormResetPassword />
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
