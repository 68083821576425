import React, { useEffect, useMemo } from "react";
import { useAPI, useFetch } from "../../api/api";
import Forms from "../../components/form/Form";
import { useFormik } from "formik";
import { Button, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { loadingStyle } from "../../common/constants";
import { ErrorText, UserScreen } from "../../common/text.json";

export default function (props) {
  const classes = useStyles();
  const api = useAPI();
  const { data, loading: LoadingInfo } = useFetch(["get", "admin/me"]);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      phone_number: "",
      email: "",
    },
    onSubmit: async (values) => {
      try {
        const res = await api.fetcher("post", "admin/updateInfo", {
          full_name: values?.full_name,
          phone_number: values?.phone_number ? values?.phone_number : null,
          email: values?.email ? values?.email : null,
          id: data?.id,
        });
        await props?.close();
      } catch (e) {
        formik.setErrors(e?.data?.error);
      }
    },
    validationSchema: Yup.object().shape({
      // Validate form field
      full_name: Yup.string()
        .required(UserScreen.full_name + " " + ErrorText.required)
        .max(
          255,
          UserScreen.full_name +
            " " +
            ErrorText.max_before +
            " " +
            255 +
            " " +
            ErrorText.max_after
        ),
      email: Yup.string()
        .max(
          255,
          UserScreen.email +
            " " +
            ErrorText.max_before +
            " " +
            255 +
            " " +
            ErrorText.max_after
        )
        .min(
          6,
          UserScreen.email +
            " " +
            ErrorText.min_before +
            " " +
            6 +
            " " +
            ErrorText.min_after
        )
        .trim()
        .nullable(),
      phone_number: Yup.string()
        .max(
          15,
          UserScreen.phone_number +
            " " +
            ErrorText.max_before +
            " " +
            15 +
            " " +
            ErrorText.max_after
        )
        .matches(/^[0-9]*$/, UserScreen.phone_number + " " + ErrorText.number)
        .nullable()
        .trim(),
    }),
  });
  useEffect(() => {
    if (data) {
      formik.setFieldValue("full_name", data?.full_name);
      formik.setFieldValue("phone_number", data?.phone_number);
      formik.setFieldValue("email", data?.email);
    }
  }, [data]);

  const inputs = useMemo(
    () => [
      [
        {
          label: UserScreen.full_name,
          value: formik.values?.full_name,
          variant: "outlined",
          handleChange: (e) => formik.setFieldValue("full_name", e),
          type: "text",
          error: formik.touched.full_name && formik.errors?.full_name,
          grid: { xs: 12, sm: 12, md: 12 },
        },
        {
          label: UserScreen.phone_number,
          value: formik.values?.phone_number,
          variant: "outlined",
          handleChange: (e) => formik.setFieldValue("phone_number", e),
          type: "text",
          error: formik.touched.phone_number && formik.errors?.phone_number,
          grid: { xs: 12, sm: 12, md: 12 },
        },
        {
          label: UserScreen.email,
          value: formik.values?.email,
          variant: "outlined",
          handleChange: (e) => formik.setFieldValue("email", e),
          error: formik.touched.email && formik.errors?.email,
          type: "text",
          grid: { xs: 12, sm: 12, md: 12 },
        },
      ],
    ],
    [formik]
  );
  return (
    <div
      className={api.loading || LoadingInfo ? classes.loading : classes.action}
    >
      <Forms inputs={inputs} />
      <Button
        color="primary"
        onClick={formik.handleSubmit}
        variant={"contained"}
      >
        Cập nhật
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  action: {
    paddingLeft: 10,
  },
  loading: loadingStyle,
}));
