import React from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/vi";
import moment from "moment";

const Date = React.memo((props) => {
  const {
    value,
    label,
    handleChange,
    error,
    helperText,
    size,
    format,
    disabled,
    views,
    ...otherProps
  } = props;
  const classes = useStyle();
  const minDate = "1900/01/01";
  return (
    <FormControl
      error={!!error}
      style={{
        width: "100%",
      }}
      {...otherProps.formControlProps}
    >
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={"vi"}
      >
        <KeyboardDatePicker
          disabled={disabled}
          className={
            size == "small" &&
            (otherProps.inputVariant == "standard"
              ? classes.smallStandard
              : classes.smallOutlined) +
              " " +
              `${error ? classes.errors : classes.normal}`
          }
          autoOk
          variant={otherProps.variant ? otherProps.variant : "inline"}
          inputVariant={
            otherProps.inputVariant ? otherProps.inputVariant : "outlined"
          }
          label={label}
          format={format ? format : "DD/MM/YYYY"}
          value={value ? value : null}
          InputAdornmentProps={{ position: "end" }}
          onChange={(date) =>  { date == null ? handleChange(null) : handleChange(date?.format("YYYY/MM/DD"))}}
          maxDate={otherProps.maxDate}
          minDate={otherProps.minDate || minDate}
          invalidDateMessage={value == undefined && "Ngày không đúng định dạng"}
          maxDateMessage={
            "Ngày lớn nhất phải trước" +
            " " +
            moment(otherProps.maxDate).format("DD-MM-YYYY")
          }
          minDateMessage={
            "Ngày nhỏ nhất sau ngày" +
            " " +
            moment(otherProps.minDate || minDate).format("DD-MM-YYYY")
          }
          views={views ? views : ["year", "month", "date"]}
          // TextFieldComponent={(TextFieldProps) => (
          //   <TextField {...TextFieldProps} size={size} />
          // )}
        />
        {/* <DatePicker
        className={error ? classes.errors : classes.normal}
        label={label}
        variant={otherProps.variant}
        inputVariant={otherProps.inputVariant}
        value={value}
        onChange={(e) => handleChange(e)}
        format="DD/MM/YYYY"
        TextFieldComponent={(TextFieldProps) => (
          <TextField {...TextFieldProps} size={size} />
        )}
        views={["date", "month", "year",]}
      /> */}
      </MuiPickersUtilsProvider>
      <FormHelperText style={{ paddingLeft: "16px", color: "#f44336" }}>
        {value == "Invalid date"
          ? "Không đúng định dạng ngày"
          : helperText
          ? helperText
          : error}
      </FormHelperText>
    </FormControl>
  );
});

const useStyle = makeStyles((theme) => ({
  errors: {
    "& label": {
      color: "#f44336",
    },
    "& fieldset": {
      borderColor: "#f44336",
    },
  },
  smallOutlined: {
    "& input": {
      padding: "10.5px 14px",
    },
    "& label": {
      transform: "translate(14px, 12px) scale(1)",
    },
  },
  smallStandard: {
    "& input": {
      padding: "10.5px 0px",
    },
  },
}));
export default Date;
