import React, { useState, useEffect } from "react";
import { TextField, Popper, Paper, ClickAwayListener } from "@material-ui/core";
import { useFetch } from "../../api/api";
import { AutoInsert } from "../../common/text.json";
import { makeStyles } from "@material-ui/core/styles";

function GetDataFromApi({
  handleChange,
  setOpen,
  setSearchParams,
  setDemoSearch,
  anchorEl,
  idInsert,
  open,
  searchParams,
  endpoint,
  demoSearch,
  queryField,
}) {
  const { data, loading } = useFetch(["get", endpoint, demoSearch]);
  const classes = useStyle();
  const [hightLight, setHightLight] = useState();
  const chooseValue = (value) => {
    setOpen(false);
    handleChange(value.name);
    setSearchParams({ [queryField]: value.name });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setDemoSearch(searchParams);
    }, 200);
    return () => clearTimeout(timer);
  }, [searchParams]);
  return (
    <Popper
      id={idInsert}
      open={open}
      anchorEl={anchorEl}
      placement={"bottom-start"}
      style={{ backgroundColor: "white", zIndex: 10000, minWidth: "200px" }}
    >
      <Paper className={classes.paperStyle}>
        {data?.data.map((value, index) => {
          return loading ? (
            <div className={classes.text} key={index}>
              {AutoInsert.loading}
            </div>
          ) : (
            <div
              className={
                hightLight == value.id
                  ? classes.itemHightLight
                  : classes.itemNotHightLight
              }
              key={index}
              onClick={() => chooseValue(value)}
              onMouseEnter={() => setHightLight(value.id)}
              onMouseLeave={() => setHightLight(0)}
            >
              {value.name}
            </div>
          );
        })}
        {loading ? (
          <div className={classes.text}>{AutoInsert.loading}</div>
        ) : data?.data?.length == 0 ? (
          <div className={classes.text} onClick={() => setOpen(false)}>
            {AutoInsert.nodata}
          </div>
        ) : (
          ""
        )}
      </Paper>
    </Popper>
  );
}

function AutocompleteInsert({
  queryField,
  handleChange,
  value,
  label,
  error,
  endpoint,
}) {
  const [searchParams, setSearchParams] = useState({
    [queryField]: "",
  });
  const changeValue = (e) => {
    handleChange(e.target.value);
    setOpen(true);
    setAnchorEl(e.currentTarget);
    setSearchParams({ [queryField]: e.target.value });
  };
  const [demoSearch, setDemoSearch] = useState({
    [queryField]: "",
  });
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const idInsert = open ? "transitions-popper" : undefined;
  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <TextField
          fullWidth={true}
          aria-describedby={idInsert}
          variant={"outlined"}
          size={"small"}
          value={value}
          onChange={(e) => changeValue(e)}
          label={label ? label : ""}
          error={error}
          helperText={error}
          onClick={(e) => {
            setOpen(true);
            setAnchorEl(e.currentTarget);
          }}
        />
        {open == true && (
          <GetDataFromApi
            handleChange={handleChange}
            setOpen={setOpen}
            setSearchParams={setSearchParams}
            setDemoSearch={setDemoSearch}
            anchorEl={anchorEl}
            idInsert={idInsert}
            open={open}
            searchParams={searchParams}
            endpoint={endpoint}
            demoSearch={demoSearch}
            queryField={queryField}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}
export default AutocompleteInsert;

const useStyle = makeStyles((theme) => ({
  itemNotHightLight: {
    padding: 10,
    borderBottom: "#f3f3f3 solid 1px",
    minHeight: 30,
    wordBreak: "keep-all",
  },
  itemHightLight: {
    padding: 10,
    borderBottom: "#f3f3f3 solid 1px",
    minHeight: 30,
    wordBreak: "keep-all",
    backgroundColor: "#c2e9fb",
  },
  text: {
    padding: 5,
    minHeight: 30,
    backgroundColor: "white",
  },
  paperStyle: {
    maxHeight: "200px",
    overflow: "auto",
    maxWidth: 500,
    backgroundColor: "white",
    minWidth: "100px",
  },
}));
