import React, { useMemo } from "react";
import Popover from "../popover";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import {
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  SvgIcon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as Help } from "../../assets/icons/field-check.svg";

const FieldCheck = ({ columns }) => {
  const classes = useStyle();
  const countCheck = useMemo(() => {
    let count = 0;
    if (columns?.columnChecked) {
      columns.columnChecked.forEach((value) => {
        if (value.display == true) {
          count = count + 1;
        }
      });
    }
    return count;
  }, [columns]);
  const FieldCheckCols = useMemo(() => {
    return (
      <div className={classes.root}>
        <div>
          <Button variant="contained" onClick={() => columns.defaultColumns()}>
            {"Mặc định"}
          </Button>
        </div>
        <Grid container>
          {columns?.columnChecked.map((column, index) => (
            <Grid item xs={4} key={index}>
              <FormControlLabel
                key={index}
                value={column.display}
                control={<Checkbox color="primary" checked={column.display} />}
                label={column.title}
                disabled={countCheck < 2 && column.display == true}
                onChange={(event) => columns.handleChangeColumns(column)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }, [columns?.columnChecked]);

  return (
    <Popover
      content={FieldCheckCols}
      children={
        <SvgIcon
          style={{ fill: "#036FE3", cursor: "pointer" }}
          viewBox="0 0 24 24"
          component={Help}
        />
      }
    ></Popover>
  );
};
const useStyle = makeStyles((themem) => ({
  root: {
    width: 500,
    padding: 10,
  },
}));
export default FieldCheck;
