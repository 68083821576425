import React, { useContext, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAPI } from "../../api/api";
import { AuthContext } from "../AuthProvider";
import { useHistory, Link } from "react-router-dom";
import "./Login.css";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  UserScreen,
  ErrorText,
  LoginScreen,
  send_otp,
} from "../../common/text.json";
import { GoogleLogin } from "react-google-login";
import google from "../../assets/image/google.png";
import phone from "../../assets/image/phone.png";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const useStyle = makeStyles(() => ({
  input: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 20,
  },
  button: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
  },
}));

export function FormLogin(props) {
  const captchaRef = useRef();
  const { disable } = props;
  const classes = useStyle();
  const history = useHistory();
  const {
    updateAdminToken,
    updateAdmin,
    updatePermission,
    updateDocumentHelpFile,
  } = useContext(AuthContext);
  const api = useAPI();
  const [error422, setError422] = useState("");
  const formik = useFormik({
    initialValues: {
      user_name: "",
      password: "",
      key: "6LfmjqYgAAAAAGyO7m-sToLqpduYHeTd-eYdfm3y",
      valueCaptcha: "",
    },
    validationSchema: Yup.object().shape({
      // Validate form field
      user_name: Yup.string().required(
        UserScreen.user_name + " " + ErrorText.required
      ),
      password: Yup.string().required(
        UserScreen.password + " " + ErrorText.required
      ),
    }),
    onSubmit: async (values) => {
      try {
        values.valueCaptcha = valueCaptcha;
        const res = await api.fetcher("post", "/login", values);
        if (res) {
          setError422("");
          console.log({ res });
          let adminData = res?.user;
          adminData.region = adminData?.region_value?.id;
          await updateAdminToken(res.token);
          const perm = await api.fetcher("get", "/me");
          const permission_group = await api.fetcher(
            "get",
            "unauth/role/" + res?.user?.role_id
          );
          await updatePermission(
            convertDataPerm(permission_group?.permissions, perm?.permissions)
          );
          await updateAdmin(adminData);
          await updateDocumentHelpFile();
          history.push("/");
        }
      } catch (e) {
        if (e?.data?.error && e?.status != 422) {
          toast.error(e?.data?.error, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError422(e?.data?.error);
        }
      }
      captchaRef.current.reset();
      setClickCaptcha(true);
      setValueCaptcha("");
    },
  });
  const loginGoogle = async (data) => {
    try {
      const res = await api.fetcher("post", "/loginGoogle", {
        email: data.email,
      });
      if (res) {
        await updateAdminToken(res.token);
        const perm = await api.fetcher("get", "/permissions");
        const permission_group = await api.fetcher(
          "get",
          "unauth/role/" + res?.user?.role_id
        );
        await updatePermission(
          convertDataPerm(permission_group?.permissions, perm)
        );
        await updateAdmin(res.user);
        history.push("/");
      }
    } catch (e) {
      if (e?.data?.error) {
        toast.error(e?.data?.error, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const convertDataPerm = (user_perm, group_perm) => {
    let array = [];
    group_perm.forEach((data_group_perm) => {
      user_perm.forEach((data_user_perm) => {
        if (data_group_perm?.id == data_user_perm?.id) {
          array.push(data_user_perm);
        }
      });
    });
    return array;
  };
  const responseGoogle = async (response) => {
    var res = response.profileObj;
    loginGoogle(res);
  };
  const ariaLabel = { "aria-label": "description" };
  const [clickCaptcha, setClickCaptcha] = useState(false);
  const [valueCaptcha, setValueCaptcha] = useState("");
  const onChange = (value) => {
    if (value) {
      setClickCaptcha(false);
      setValueCaptcha(value);
    } else {
      setClickCaptcha(true);
      setValueCaptcha("");
    }
  };
  return disable ? (
    <form style={{ padding: 10 }}>
      <Grid className={classes.input}>
        <Input
          className="input1"
          value={formik.values?.user_name}
          error={
            (formik.errors?.user_name && formik.touched.user_name) ||
            api.error?.user_name
          }
          helperText={
            (formik.touched.user_name && formik.errors?.user_name) ||
            api.error?.user_name
          }
          placeholder={LoginScreen.enter_user_name}
          startAdornment={
            <InputAdornment position="start">
              <PersonOutlineOutlinedIcon
                style={{ fontSize: 25 }}
                color="action"
              />
            </InputAdornment>
          }
          autoFocus={true}
          inputProps={ariaLabel}
        />
        <FormHelperText error={true}>
          {(formik.touched.user_name && formik.errors?.user_name) ||
            api.error?.user_name}
        </FormHelperText>
      </Grid>
      <Grid className={classes.input}>
        <Input
          className="input1"
          type="password"
          value={formik.values?.password}
          error={
            (formik.errors?.password && formik.touched.password) ||
            api.error?.password ||
            error422
          }
          placeholder={LoginScreen.enter_password}
          startAdornment={
            <InputAdornment position="start">
              <LockOutlinedIcon style={{ fontSize: 25 }} color="action" />
            </InputAdornment>
          }
          inputProps={ariaLabel}
        />
        <FormHelperText error={true}>
          {(formik.touched.password && formik.errors?.password) ||
            api.error?.password ||
            error422}
        </FormHelperText>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReCAPTCHA
            ref={captchaRef}
            sitekey="6LfmjqYgAAAAAH4d2eYuD1pdoFxTf7Rj15AFaY5N"
            onChange={onChange}
            style={{ marginTop: 10 }}
          />
        </div>
        <Link className={classes.button}>{LoginScreen.forgot_password}</Link>
      </Grid>
      <Grid>
        <Button
          variant="contained"
          color="primary"
          disabled={clickCaptcha}
          style={{ marginBottom: 10, width: "100%", borderRadius: 10 }}
        >
          {LoginScreen.login}
        </Button>
        <Button
          style={{
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            color: "white",
            backgroundColor: "#36c836",
            padding: "3px 16px",
          }}
          variant="contained"
        >
          <img
            alt=""
            src={phone}
            width={30}
            height={30}
            style={{ marginRight: 10 }}
          />
          {send_otp.login_with_SMS}
        </Button>
        <GoogleLogin
          render={(renderProps) => (
            <Button
              variant="contained"
              style={{
                width: "100%",
                borderRadius: 10,
                backgroundColor: "#dd4b39",
                color: "white",
                padding: "0px 16px",
              }}
            >
              <img
                alt=""
                src={google}
                width={35}
                height={35}
                style={{ marginRight: 10 }}
              />
              {LoginScreen.google_login}
            </Button>
          )}
          clientId={
            process.env.GOOGLE_CLIENT_ID
              ? process.env.GOOGLE_CLIENT_ID
              : "30835151183-iofkjm8sel9e3vkknn57l2uh5lb6pp7m.apps.googleusercontent.com"
          }
        />
      </Grid>
    </form>
  ) : (
    <form style={{ padding: 10 }}>
      <Grid className={classes.input}>
        <Input
          className="input1"
          value={formik.values?.user_name}
          onChange={(e) => formik.setFieldValue("user_name", e.target.value)}
          error={
            (formik.errors?.user_name && formik.touched.user_name) ||
            api.error?.user_name
          }
          helperText={
            (formik.touched.user_name && formik.errors?.user_name) ||
            api.error?.user_name
          }
          placeholder={LoginScreen.enter_user_name}
          startAdornment={
            <InputAdornment position="start">
              <PersonOutlineOutlinedIcon
                style={{ fontSize: 25 }}
                color="action"
              />
            </InputAdornment>
          }
          autoFocus={true}
        />
        <FormHelperText error={true}>
          {(formik.touched.user_name && formik.errors?.user_name) ||
            api.error?.user_name}
        </FormHelperText>
      </Grid>
      <Grid className={classes.input}>
        <Input
          className="input1"
          type="password"
          value={formik.values?.password}
          onChange={(e) => {
            formik.setFieldValue("password", e.target.value);
            setError422("");
          }}
          error={
            (formik.errors?.password && formik.touched.password) ||
            api.error?.password ||
            error422
          }
          placeholder={LoginScreen.enter_password}
          startAdornment={
            <InputAdornment position="start">
              <LockOutlinedIcon style={{ fontSize: 25 }} color="action" />
            </InputAdornment>
          }
        />
        <FormHelperText error={true}>
          {(formik.touched.password && formik.errors?.password) ||
            api.error?.password ||
            error422}
        </FormHelperText>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReCAPTCHA
            ref={captchaRef}
            sitekey="6LfmjqYgAAAAAH4d2eYuD1pdoFxTf7Rj15AFaY5N"
            onChange={onChange}
            style={{ marginTop: 10 }}
          />
        </div>
        <Link className={classes.button} to={"/forgotPassword"}>
          {LoginScreen.forgot_password}
        </Link>
      </Grid>
      <Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={formik.handleSubmit}
          disabled={clickCaptcha}
          style={{ marginBottom: 10, width: "100%", borderRadius: 10 }}
        >
          {LoginScreen.login}
        </Button>
        <Link className={classes.button} to={"/sendOTP"}>
          <Button
            style={{
              marginBottom: 10,
              width: "100%",
              borderRadius: 10,
              color: "white",
              backgroundColor: "#36c836",
              padding: "3px 16px",
            }}
            variant="contained"
          >
            <img
              alt=""
              src={phone}
              width={30}
              height={30}
              style={{ marginRight: 10 }}
            />
            {send_otp.login_with_SMS}
          </Button>
        </Link>
        <GoogleLogin
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              variant="contained"
              style={{
                width: "100%",
                borderRadius: 10,
                backgroundColor: "#dd4b39",
                color: "white",
                padding: "0px 16px",
              }}
            >
              <img
                alt=""
                src={google}
                width={35}
                height={35}
                style={{ marginRight: 10 }}
              />
              {LoginScreen.google_login}
            </Button>
          )}
          clientId={
            process.env.GOOGLE_CLIENT_ID
              ? process.env.GOOGLE_CLIENT_ID
              : "30835151183-iofkjm8sel9e3vkknn57l2uh5lb6pp7m.apps.googleusercontent.com"
          }
          onSuccess={(res) => responseGoogle(res)}
          onFailure={responseGoogle}
        />
      </Grid>
    </form>
  );
}
