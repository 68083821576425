import React, { useEffect, useMemo, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useLocation } from "react-router-dom";

const Radios = React.memo((props) => {
  const {
    label,
    value,
    error,
    handleChange,
    options,
    flexDirection,
    disabled,
    ...otherProps
  } = props;
  const location = useLocation();
  const [firstCheck, setFirstCheck] = useState(false);
  useEffect(() => {
    if (location?.search && firstCheck == false) {
      let searchParams = location.search.split("&");
      searchParams.forEach((element) => {
        let data_params = element.split("=");
        let status = data_params[1];
        if (data_params[0] == "?status") {
          handleChange(status);
        }
      });
      setFirstCheck(true);
      setFirstCheck(true);
    }
  }, [location, firstCheck]);
  return (
    <FormControl
      component="fieldset"
      error={!!error}
      {...otherProps.formControlProps}
      style={otherProps?.style == 1 ? { marginBottom: "-10px", marginTop: "-20px" } : {}}
    >
      <div
        style={{
          display: "flex",
          direction: "row",
          gap: 8,
          alignItems: "center",
        }}
      >
        <FormLabel
          component="legend"
          {...otherProps.formLabelProps}
          style={{ color: "#0000008A" }}
        >
          {label ? label + ":" : label}
        </FormLabel>
        <RadioGroup
          name="position"
          //if want row => style {flexDirection: "row"}
          // {...otherProps.radioGroupProps}
          style={{ flexDirection: flexDirection ? flexDirection : "row" }}
        >
          {options.map((e, index) => (
            <FormControlLabel
              disabled={disabled}
              key={e.label}
              value={e.value}
              control={
                <Radio
                  color="primary"
                  {...otherProps.radioProps}
                  checked={
                    e.value == "" || e.value == null
                      ? value == "" || value == undefined
                      : e.value == value
                  }
                />
              }
              label={e.label}
              onChange={(e) => handleChange(e.target.value)}
              {...otherProps.formControlLabelProps}
            />
          ))}
        </RadioGroup>
      </div>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
});

export default Radios;
