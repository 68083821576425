import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/vi";
import moment from "moment";
import { KeyboardTimePicker } from "@material-ui/pickers";

const Time = React.memo((props) => {
  const {
    value,
    label,
    handleChange,
    error,
    helperText,
    size,
    format,
    disablePast,
    ...otherProps
  } = props;
  const classes = useStyle();
  const [ownVal, setOwnVal] = useState(null);
  useEffect(() => {
    if (value) {
      if (typeof value == "string") {
        setOwnVal(new Date(`2022-01-01 ${value} GMT+0700 (Indochina Time)`));
      } else {
        setOwnVal(value);
      }
    }
  }, [value]);
  return (
    <FormControl
      error={!!error}
      style={{
        width: "100%",
      }}
      {...otherProps.formControlProps}
    >
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={"vi"}
      >
        <KeyboardTimePicker
          {...props}
          variant={otherProps.variant ? otherProps.variant : "static"}
          inputVariant={
            otherProps.inputVariant ? otherProps.inputVariant : "outlined"
          }
          placeholder="08:00"
          ampm={false}
          value={ownVal}
          onChange={(date) => handleChange(date)}
          invalidDateMessage={() => <div>Thời gian bị sai định dạng</div>}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText>{helperText ? helperText : error}</FormHelperText>
    </FormControl>
  );
});

const useStyle = makeStyles((theme) => ({
  errors: {
    "& label": {
      color: "#f44336",
    },
    "& fieldset": {
      borderColor: "#f44336",
    },
  },
  small: {
    "& input": {
      padding: "10.5px 14px",
    },
    "& label": {
      transform: "translate(14px, 12px) scale(1)",
    },
  },
}));
export default Time;
