import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAPI } from "../../api/api";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import PhoneIcon from "@material-ui/icons/Phone";
import { firebase, auth } from "./firebase";
import Dialog from "@material-ui/core/Dialog";
import { AuthContext } from "../AuthProvider";
import { send_otp, ErrorText } from "../../common/text.json";
import OtpInput from "react-otp-input";

const useStyle = makeStyles(() => ({
  input: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 20,
  },
  otp_input: {
    "&:focus": {
      border: "1px solid red",
    },
  },
}));

export default function SendOTP(props) {
  const classes = useStyle();
  const api = useAPI();
  const history = useHistory();
  const { updateAdminToken, updateAdmin, updatePermission } = useContext(
    AuthContext
  );

  const [phoneNumber, setPhoneNumber] = useState();
  const [code, setCode] = useState("");
  const [dialog, setDialog] = useState(false);
  const [confirmCode, setConfirmCode] = useState();
  const [error, setError] = useState();
  const [validateNumber, setValidateNumber] = useState(false);

  const signIn = async () => {
    if (phoneNumber) {
      setValidateNumber(false);
      try {
        const res = await api.fetcher("post", "/checkPhoneNumber", {
          phone_number: phoneNumber,
        });
        if (res) {
          let verify = (window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "sign-in-button",
            {
              size: "invisible",
              callback: (response) => {
                signIn();
              },
            }
          ));
          auth
            .signInWithPhoneNumber("+84" + phoneNumber, verify)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              setConfirmCode(confirmationResult);
              setDialog(true);
            })
            .catch((err) => {
              return err;
            });
        }
      } catch (error) {
        setDialog(false);
      }
    } else {
      setValidateNumber(true);
    }
  };

  const verify = () => {
    if (code) {
      confirmCode
        .confirm(code)
        .then(async (result) => {
          try {
            const res = await api.fetcher("post", "/loginByPhone", {
              phone_number: phoneNumber,
            });
            if (res) {
              await updateAdminToken(res.token);
              const perm = await api.fetcher("get", "/me");
              await updatePermission(perm?.permissions);
              await updateAdmin(res.user);
              history.push("/");
            }
          } catch (error) {}
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const send_again = () => {
    window.location.reload();
    setDialog(false);
  };

  const handleClose = () => {
    setDialog(false);
  };
  const checkEnterPress = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      signIn();
    }
  };
  return (
    <form style={{ padding: 10 }}>
      <Grid className={classes.input}>
        <Input
          className="input1"
          type="number"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setValidateNumber(false);
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 9);
          }}
          id="input-with-icon-adornment"
          placeholder={send_otp.type_sdt}
          startAdornment={
            <InputAdornment position="start">
              <PhoneIcon style={{ fontSize: 25 }} color="action" />
              +84
            </InputAdornment>
          }
          onKeyDown={(e) => {
            checkEnterPress(e);
          }}
          onKeyPress={(e) => {
            checkEnterPress(e);
          }}
        />
        {!validateNumber ? null : (
          <FormHelperText
            style={{ marginTop: 5, marginLeft: 30 }}
            error={validateNumber}
          >
            {send_otp.sdt + " " + ErrorText.required}
          </FormHelperText>
        )}
      </Grid>
      <Grid className={classes.button}>
        <Button
          id="sign-in-button"
          onClick={() => signIn()}
          color="primary"
          variant="contained"
          style={{ marginBottom: 10, width: "100%", borderRadius: 10 }}
        >
          {send_otp.verify}
        </Button>
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
          }}
        >
          <Button
            variant="contained"
            style={{
              marginBottom: 10,
              width: "100%",
              borderRadius: 10,
              color: "white",
              backgroundColor: "#36c836",
            }}
          >
            Quay lại
          </Button>
        </Link>
      </Grid>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className={classes.input}
          style={{ width: 350, padding: "30px 20px" }}
        >
          <h1 style={{ fontWeight: 600, fontSize: 20, color: "#000000c9" }}>
            {send_otp.verify_sdt}
          </h1>
          <span style={{ fontSize: 16, marginBottom: 20 }}>
            {send_otp.type_otp_sent + " +84" + phoneNumber}
          </span>
          <OtpInput
            value={code}
            onChange={(e) => setCode(e)}
            numInputs={6}
            separator={<span style={{ width: 13 }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid #CFD3DB",
              borderRadius: 4,
              width: 40,
              height: 40,
              fontSize: 20,
              color: "#000",
              caretColor: "#396486",
            }}
            focusStyle={{
              border: "1px solid #0000006e",
              outline: "none",
            }}
          />
          {code ? null : (
            <FormHelperText
              style={{ marginTop: 5, marginLeft: 5 }}
              error={true}
            >
              {send_otp.otp + " " + ErrorText.required}
            </FormHelperText>
          )}
          {error != undefined ? (
            <FormHelperText
              style={{ marginTop: 5, marginLeft: 5 }}
              error={true}
            >
              {send_otp.otp_invalidate}
            </FormHelperText>
          ) : null}
          <Button
            id="sign-in-button"
            onClick={verify}
            color="primary"
            variant="contained"
            style={{
              marginTop: 15,
              marginBottom: 10,
              width: "100%",
              borderRadius: 10,
            }}
          >
            {send_otp.login}
          </Button>
          {error == undefined ? null : (
            <Button
              id="sign-in-button"
              onClick={send_again}
              color="primary"
              variant="contained"
              style={{
                marginTop: 15,
                marginBottom: 10,
                width: "100%",
                borderRadius: 10,
              }}
            >
              {send_otp.send_again}
            </Button>
          )}
        </div>
      </Dialog>
    </form>
  );
}
