import { Grid, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import Date from "../../form/Date";

export default function DateType({
  value,
  changeValue,
  changeDateType,
  index,
  hideDevoteeType,
}) {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Typography
          style={{ fontSize: "12px", color: grey[900], fontWeight: "bold" }}
        >
          {hideDevoteeType != true && value?.devotee_type?.name}
          {hideDevoteeType != true && ": "}
          {hideDevoteeType != true && value?.input_name}
        </Typography> */}
        <Typography
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            fontSize: "12px",
            fontWeight: 800,
            color: "#17488a",
          }}
        >
          <Tooltip title={value?.input_name}>
            <span
              style={{
                fontSize: "12px",
                borderRadius: "50%",
                border: "blue solid 1px",
                cursor: "pointer",
                marginRight: "5px",
                width: "16px",
                height: "16px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                color: "blue",
              }}
            >
              i
            </span>
          </Tooltip>
          {value?.input_name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Date
          value={value.start ? value.start : ""}
          label="Từ"
          handleChange={(e) => changeDateType(e, index, "start")}
          size={"small"}
          fullWidth
          format={"DD/MM/YYYY"}
        />
      </Grid>
      <Grid item xs={4}>
        <Date
          disabled={value.start ? false : true}
          minDate={value.start}
          value={value.end ? value.end : ""}
          label="Đến"
          handleChange={(e) => changeDateType(e, index, "end")}
          size={"small"}
          fullWidth
          format={"DD/MM/YYYY"}
        />
      </Grid>
    </Grid>
  );
}
