import React, { useContext, Suspense } from "react";
import { adminPrefix } from "./AdminRoutes";
import { AuthContext } from "../containers/AuthProvider";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "../containers/Layout/Layout";
import AppLoading from "../components/Loading";
import Login from "../containers/Auth/Login";
import { peoplePrefix } from "./PeopleRoutes";

export default function MainRouter(props) {
  const { admin, user } = useContext(AuthContext);

  return (
    <Router>
      <Suspense fallback={<AppLoading />}>
        <Switch>
          <Route path={"/login"} name="Login" exact strict component={Login} />
          <Route
            path={"/resetPassword/:token"}
            name="Login"
            exact
            strict
            component={Login}
          />
          <Route
            path={peoplePrefix}
            name="people"
            component={user ? Layout : Login}
          />
          <Route
            path={adminPrefix}
            name="admin"
            component={admin ? Layout : Login}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}
