import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAPI } from "../../api/api";
import "./Login.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyle = makeStyles(() => ({
  input: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 20,
  },
}));

export function FormForgotPassword(props) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleChangeOpen = (value) => {
    setOpen(value);
  };

  const api = useAPI();
  const formik = useFormik({
    initialValues: {
      user_name: "",
    },
    validationSchema: Yup.object().shape({
      // Validate form field
      user_name: Yup.string().required("Tên đăng nhập không được bỏ trống"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await api.fetcher("post", "/forgotPassword", values);
        if (res?.status == 404) {
          setOpen(true);
          setMessage(
            "Tài khoản chưa được cài đặt email để thực hiện thao tác quên mật khẩu! \nVui lòng liên hệ cán bộ quản trị cùng cấp hoặc cán bộ cấp trên để được hỗ trợ cài đặt lại mật khẩu."
          );
        }
      } catch (e) {}
    },
  });
  return (
    <form style={{ padding: 10 }}>
      <Grid className={classes.input} style={{ marginTop: 57 }}>
        <TextField
          id="standard-basic"
          value={formik.values?.user_name}
          onChange={(e) => formik.setFieldValue("user_name", e.target.value)}
          error={
            (formik.errors?.user_name && formik.touched.user_name) ||
            api.error?.user_name
          }
          helperText={
            (formik.touched.user_name && formik.errors?.user_name) ||
            api.error?.user_name
          }
          placeholder={"Nhập tên đăng nhập"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon
                  style={{ fontSize: 25 }}
                  color="action"
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid className={classes.button}>
        <Button
          onClick={formik.handleSubmit}
          color="primary"
          variant="contained"
          disabled={api.loading}
          startIcon={
            api.loading ? (
              <CircularProgress
                style={{ width: "20px", height: "20px", color: "white" }}
              />
            ) : (
              <div></div>
            )
          }
          style={{ marginBottom: 10, width: "100%", borderRadius: 10 }}
        >
          Lấy lại mật khẩu
        </Button>
      </Grid>
      <Dialog onClose={() => handleChangeOpen(false)} open={open}>
        <DialogTitle>Quên mật khẩu</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: "break-spaces" }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} autoFocus>
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
